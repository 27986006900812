import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Headline from '../../Elements/Headline'
import { Modal } from '../../Elements/Modal'
import Text from '../../Elements/Text'
import Button from '../../Formik/Button'
import { createBudget } from '../lib/API/BudgetAPI'

const CreateBudget = () => {
  const [showModal, toggleModal] = useState(false)
  const navigate = useNavigate()

  const fromTemplate = async () => {
    const budget = await createBudget(true)

    return navigate(`/budget/${budget.id}/`)
  }

  const fromScratch = async () => {
    const budget = await createBudget(false)

    return navigate(`/budget/${budget.id}/`)
  }

  return (
    <>
      <Modal show={showModal} onHide={() => toggleModal(false)} wide>
        <Headline className='mb-5'>Nyt Budget</Headline>
        <div className='flex'>
          <div className='w-1/2 mr-5 border-r'>
            <Headline size='lg'>Opret fra en skabelon</Headline>
            <img
              src='/images/budget_template.png'
              className='w-2/3 my-5 border'
            />
            <Text className='mb-5'>
              Hvis du ønsker et godt udgangspunkt for dit budget, kan du vælge
              at starte fra vores skabelon. Så indeholder dit budget alle
              relevante kategorier fra start.
            </Text>
            <Button onClick={fromTemplate}>Vælg</Button>
          </div>
          <div className='w-1/2 ml-5 flex flex-col'>
            <Headline size='lg'>Opret et tomt budget</Headline>
            <img
              src='/images/budget_scratch.png'
              className='w-2/3 my-5 border'
            />
            <Text className='mb-5'>
              Opret et tomt budget, hvis du selv vil have fuld kontrol over
              kategorierne i dit budget.
            </Text>
            <Button onClick={fromScratch}>Vælg</Button>
          </div>
        </div>
      </Modal>
      <Button
        onClick={() => toggleModal(true)}
        className='ml-0 md:ml-2 mb-4 md:mb-0'
      >
        Opret et Budget
      </Button>
    </>
  )
}

export default CreateBudget

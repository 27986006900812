import { FC, useEffect, useState } from 'react'
import { ReactSortable } from 'react-sortablejs'
import { Sheet } from '../../../lib/Types'
import Headline from '../../Elements/Headline'
import Text from '../../Elements/Text'
import { useCategories, useUpdateCategory } from '../lib/Hooks/Categories'
import Category from './Category'
import CategoryAdd from './CategoryAdd'
import { getSingularName } from './lib/Naming'

type Props = {
  sheet: Sheet
}
const CategoriesForm: FC<Props> = ({ sheet }) => {
  const { data: categories } = useCategories(sheet)
  const { mutateAsync: updateCategory } = useUpdateCategory(sheet)
  const [stateCategories, setStateCategories] = useState(categories)

  useEffect(() => {
    setStateCategories(categories)
  }, [categories])

  return (
    <>
      <Headline className='mb-5'>Kategorier</Headline>
      <Text className='mb-5'>
        Du kan her redigere de kategorier der skal være mulige at vælge for
        udgifterne i dit {getSingularName(sheet.sheet_type)}. Hvis et{' '}
        {getSingularName(sheet.sheet_type)} kun har én kategori, vil den
        kategori være valgt som standard, og du vil ikke skulle vælge en
        kategori, når du tilføjer en udgift.
      </Text>
      <ReactSortable
        group='categories'
        list={stateCategories}
        setList={async (reorderedCategories, _, { dragging }) => {
          if (!dragging) {
            return
          }

          setStateCategories(reorderedCategories)

          reorderedCategories.forEach((category, index) => {
            if (category.order !== index) {
              category.order = index
              updateCategory(category)
            }
          })
        }}
        dragClass='list-selected'
        ghostClass='list-placeholder'
        draggable='.draggable'
        handle='.draggable-handle'
      >
        {stateCategories!.map((category) => (
          <Category key={category.id} sheet={sheet} category={category} />
        ))}
      </ReactSortable>
      <CategoryAdd sheet={sheet} />
    </>
  )
}

export default CategoriesForm

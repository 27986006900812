import { FC } from 'react'
import { MdSync } from 'react-icons/md'

type Props = {
  showText?: boolean
  className?: string
}

const LoadingSpinner: FC<Props> = ({ showText = false, className }) => {
  return (
    <div className='flex flex-col md:flex-row'>
      <MdSync className={`animate-spin ${className}`} size={30} />
    </div>
  )
}

export default LoadingSpinner

import { FC } from 'react'
import { formatDate } from '../../../lib/Dates'
import { Spend } from '../../../lib/Types'
import LineChart from '../../Charts/LineChart'
import Headline from '../../Elements/Headline'

type Props = {
  spend: Spend
}

const SpendDevelopment: FC<Props> = ({ spend }) => {
  const data = spend.amounts.reduce(
    (obj, spend) => {
      const createdDate = spend.created_date
        ? new Date(spend.created_date)
        : new Date()
      obj[formatDate(createdDate, 'yyyy-mm-dd')] = spend.amount
      return obj
    },
    {} as Record<string, number>,
  )

  return (
    <>
      <Headline>Udvikling</Headline>
      <p className='mt-2 mb-5'>
        Her kan du se udviklingen af den enkelte postering.
      </p>
      {spend.amounts.length === 0 && (
        <p className='italic'>Der er ikke nok data til at vise grafen</p>
      )}
      <LineChart data={data} />
    </>
  )
}

export default SpendDevelopment

const DefaultText = () => {
  return (
    <>
      <p className='font-medium text-gray-600'>
        Hvad kan AI assistenten hjælpe dig med?
      </p>

      <ul className='list-disc space-y-2 ml-5 mt-2 text-gray-600'>
        <li>
          Give dig informationer omkring regnskab og budgettering generelt.
          <br />
          Prøv f.eks. med{' '}
          <span className='font-medium italic'>
            Hvorfor skal jeg have et budget?
          </span>
        </li>
        <li>
          Give dig vejledning omkring dit specfikke regnskab eller budget.
          <br />
          Prøv f.eks. med{' '}
          <span className='font-medium italic'>
            Hvordan kunne jeg have sparet 1000,- kr sidste måned?
          </span>
        </li>
        <li>
          I et budget kan den hjælpe dig med alle funktioner.
          <br />
          Prøv f.eks. med{' '}
          <span className='font-medium italic'>
            Tilføj Husleje på 15.000 kr hver måned´
          </span>
        </li>
      </ul>
    </>
  )
}

export default DefaultText

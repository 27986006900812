import { FC, useState } from 'react'
import { formatCurrency } from '../../../lib/CurrencyFormatter'
import { SheetCategory } from '../../../lib/Types'
import BarChart from '../../Charts/BarChart'
import { useAccounting } from '../lib/Hooks/Accounting'
import { useCategories } from '../lib/Hooks/Categories'
import { useExpenses } from '../lib/Hooks/Spends'

type Props = {
  from: Date
  to: Date
}

const Category: FC<Props> = ({ from, to }) => {
  const { data: accounting } = useAccounting()
  const { data: categories } = useCategories(accounting!)
  const { data: spends } = useExpenses(accounting!, {
    from,
    to,
  })

  const getAmountForCategory = (category: SheetCategory) => {
    const amount = spends!
      .filter((spend) => spend.category.id === category.id)
      .reduce((acc, spend) => acc + spend.amounts.at(-1)!.amount, 0)

    return amount
  }

  const [selectedCategories, toggleCategories] = useState(
    categories?.filter((category) => getAmountForCategory(category) !== 0),
  )

  const isCategorySelected = (category: SheetCategory) => {
    return !!selectedCategories?.find(
      (selectedCategory) => selectedCategory.id === category.id,
    )
  }

  return (
    <tr className='bg-white border-x border-b border-gray-300 cursor-pointer'>
      <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
        <div className='flex flex-wrap items-center'>
          {categories!.map((category: SheetCategory) => (
            <div
              key={category.id}
              className='mr-4 cursor-pointer'
              onClick={() => {
                if (isCategorySelected(category)) {
                  toggleCategories(
                    selectedCategories!.filter(
                      (selectedCategory) => selectedCategory.id !== category.id,
                    ),
                  )
                } else {
                  toggleCategories(selectedCategories!.concat(category))
                }
              }}
            >
              <input
                type='checkbox'
                className='mr-2'
                checked={isCategorySelected(category)}
              />
              <label>{category.name}</label>
            </div>
          ))}
        </div>
        <BarChart
          data={selectedCategories!.map((category) => ({
            name: category.name,
            value: getAmountForCategory(category),
            color:
              category.budget &&
              getAmountForCategory(category) > category.budget
                ? 'red'
                : 'green',
          }))}
          tooltipCallback={(dataIndex) => {
            const category = selectedCategories![dataIndex]

            const content = [
              `I alt: ${formatCurrency(getAmountForCategory(category))}`,
            ]

            if (category.budget) {
              content.push(`Budget: ${category.budget}`)
            }

            return content.join(' - ')
          }}
        />
      </td>
    </tr>
  )
}

export default Category

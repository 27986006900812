import { useFormikContext } from 'formik'
import { FC, useState } from 'react'
import { MdDone } from 'react-icons/md'
import Button from './Formik/Button'
import Input from './Formik/Input'
import Select from './Formik/Select'
import { DistributionType } from './Sheets/lib/Enums/DistributionType'

type DistributionValues = {
  distribution_type: DistributionType
  distribution_primary_user: number
  distribution_secondary_user: number
}

type Props = {
  nameInput: 'distribution_primary_user' | 'distribution_secondary_user'
  types: DistributionType[]
  max?: boolean | number
}

const Distribution: FC<Props> = ({ nameInput, types, max = false }) => {
  const [distributionError, setDistributionError] = useState<boolean | string>(
    false,
  )
  const { values, submitForm } = useFormikContext<DistributionValues>()

  const save = () => {
    if (
      values.distribution_type === DistributionType.PERCENTAGE &&
      (values[nameInput] < 0 || values[nameInput] > 100)
    ) {
      setDistributionError(
        values[nameInput] < 0
          ? 'Procent kan ikke være under 0'
          : 'Procent kan ikke være over 100',
      )
      return
    }

    if (
      values.distribution_type === DistributionType.CURRENCY &&
      max &&
      values[nameInput] > max
    ) {
      setDistributionError(`Beløbet må ikke overstige ${max}`)
      return
    }

    submitForm()
  }

  return (
    <>
      <div className='relative'>
        <Input
          type='number'
          size='sm'
          inputClassName={`text-right ${types.length > 1 ? 'pr-14' : 'pr-10'} ${distributionError ? 'border-red-400' : ''}`}
          name={nameInput}
        />
        <Button
          className={`absolute top-10 left-0 w-full ${distributionError ? 'bg-red-400' : ''}`}
          onClick={save}
        >
          <MdDone />
        </Button>
        {types.length === 1 && (
          <span className='absolute top-1 right-1 w-auto border-0 px-0 m-1 h-6 py-0 shadow-none'>
            {types[0] === DistributionType.CURRENCY ? 'DKK' : '%'}
          </span>
        )}
        {types.length > 1 && (
          <>
            <Select
              name='distribution_type'
              className='absolute top-0 right-1 w-auto border-0 px-0 m-1 h-6 py-0 shadow-none'
            >
              <option value={DistributionType.PERCENTAGE}>%</option>
              <option value={DistributionType.CURRENCY}>DKK</option>
            </Select>
          </>
        )}
        {distributionError && (
          <span className='absolute top-20 left-0 w-full text-red-400'>
            {distributionError}
          </span>
        )}
      </div>
    </>
  )
}

export default Distribution

export function formatCurrency(
  amount: number,
  showDecimals: boolean = true,
): string {
  if (isNaN(amount)) {
    return `0`
  }
  if (amount === 0) {
    return `${amount}`
  }

  const formatter = new Intl.NumberFormat('da-DK', {
    style: 'currency',
    currency: 'DKK',
    minimumIntegerDigits: 2,
    minimumFractionDigits: showDecimals ? 2 : 0,
    maximumFractionDigits: showDecimals ? 2 : 0,
  })

  const formattedAmount = formatter.format(amount)

  // Remove any leading zeros and the currency symbol ( kr.) at the end
  return formattedAmount
    .replace(/^0+(?!,)/, '')
    .replace(/^-0+(?!,)/, '-')
    .replace('kr.', '')
}

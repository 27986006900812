import { Form, Formik, FormikValues } from 'formik'
import { useState } from 'react'
import { Navigate } from 'react-router-dom'
import * as Yup from 'yup'
import Headline from '../Components/Elements/Headline'
import Button from '../Components/Formik/Button'
import Input from '../Components/Formik/Input'
import * as UserAPI from '../lib/API/UserAPI'
import { useAuthenticatedUser } from '../lib/Hooks/User'
import { isCurrency } from '../lib/Validations'

export default function ProfilePage() {
  const { data: authenticatedUser } = useAuthenticatedUser()
  const [isLoading, toggleLoading] = useState(false)

  if (!authenticatedUser) {
    return <Navigate to='/login' />
  }

  return (
    <>
      <Headline className='mb-5'>Min Profil</Headline>
      <Formik
        initialValues={{
          name: authenticatedUser.name,
          email: authenticatedUser.email,
          salary: authenticatedUser.data.salary
            ? authenticatedUser.data.salary.toString().replaceAll('.', ',')
            : '',
        }}
        validationSchema={Yup.object({
          name: Yup.string().required('Påkrævet'),
          email: Yup.string().required('Påkrævet'),
          salary: Yup.string()
            .transform((currentValue) => {
              // First remove all danish thousand seperators (.) and then replace the danish delimiter seperator (,) with the english one (.).
              // This makes it's possible to save floats.
              return currentValue.replaceAll('.', '').replaceAll(',', '.')
            })
            .test(
              isCurrency.name,
              'Beløbet skal være et tal eller en formel',
              isCurrency.test,
            ),
        })}
        onSubmit={async (values: FormikValues) => {
          toggleLoading(true)

          const salary = values.salary.replaceAll('.', '').replaceAll(',', '.')

          const newUser = {
            ...authenticatedUser,
            name: values.name,
            data: { ...authenticatedUser.data, salary },
          }
          await UserAPI.saveUser(newUser)
          toggleLoading(false)
        }}
      >
        <Form>
          <Headline size='lg' className='mb-5'>
            Konto
          </Headline>
          <p className='mb-2'>Navn</p>
          <Input name='name' placeholder='Navn' className='mb-5' />
          <p className='mb-2'>E-mail</p>
          <Input name='email' placeholder='E-mail' className='mb-5' disabled />
          <Button
            type='submit'
            disabled={isLoading}
            loading={isLoading}
            showLoadingFeedback
          >
            Gem ændringer
          </Button>
        </Form>
      </Formik>
    </>
  )
}

import { FC, useContext, useEffect, useState } from 'react'
import { MdPostAdd } from 'react-icons/md'
import { ReactSortable } from 'react-sortablejs'
import { sum, sumForUser } from '../../../lib/Calculator'
import { SpendContext } from '../../../lib/Context'
import { formatCurrency } from '../../../lib/CurrencyFormatter'
import { UserType } from '../../../lib/Enums/UserType'
import { generateIdForItem } from '../../../lib/IdGenerator'
import { SheetCategory, Spend } from '../../../lib/Types'
import Button from '../../Formik/Button'
import { SpendFrequency } from '../lib/Enums/SpendFrequency'
import { useBudget } from '../lib/Hooks/Budget'
import AddSpend from './AddSpend'
import SpendComponent from './SpendComponent'

interface Props {
  category: SheetCategory
  spends: Spend[]
}

const Spends: FC<Props> = ({ category, spends }) => {
  const [items, setItems] = useState(spends)
  const [categoryToAddTo, setCategoryToAddTo] = useState<SheetCategory | null>(
    null,
  )
  const { data: budget } = useBudget()
  const { saveOrder } = useContext(SpendContext)

  useEffect(() => {
    setItems(spends)
  }, [spends])

  return (
    <>
      <AddSpend
        toggleHide={() => setCategoryToAddTo(null)}
        show={categoryToAddTo !== null}
        category={categoryToAddTo || undefined}
      />
      <table
        className={`min-w-full table-budget ${budget!.user ? 'col-5' : ''}`}
      >
        <tbody>
          <tr className='bg-gray-100 border-b border-x border-gray-300'>
            <th className='text-sm font-medium text-gray-900 px-6 py-4 text-left'>
              <div className='flex items-center justify-between'>
                {category.name}
                <Button
                  theme='cyan'
                  onClick={() => setCategoryToAddTo(category)}
                >
                  <MdPostAdd size='1rem' />
                </Button>
              </div>
            </th>
            <th className='text-sm font-medium text-gray-900 px-6 py-4 text-left'>
              {formatCurrency(sum(items, SpendFrequency.YEARLY))}
            </th>
            <th className='text-sm font-medium text-gray-900 px-6 py-4 text-left'>
              {formatCurrency(sum(items, SpendFrequency.MONTHLY))}
            </th>
            {budget!.user && (
              <>
                <th className='text-sm font-medium text-gray-900 px-6 py-4 text-left'>
                  {formatCurrency(sumForUser(items, UserType.PRIMARY))}
                </th>
                <th className='text-sm font-medium text-gray-900 px-6 py-4 text-left'>
                  {formatCurrency(sumForUser(items, UserType.SECONDARY))}
                </th>
              </>
            )}
          </tr>
        </tbody>
      </table>
      <table
        className={`min-w-full table-budget ${budget!.user ? 'col-5' : ''}`}
      >
        <ReactSortable
          tag='tbody'
          group={`category-${category.id}`}
          list={items}
          delay={3000}
          delayOnTouchOnly={true}
          setList={(reorderedItems, _, { dragging }) => {
            if (!dragging) {
              return
            }

            saveOrder(reorderedItems)
            setItems(reorderedItems)
          }}
          dragClass='list-selected'
          ghostClass='list-placeholder'
          draggable='.draggable'
        >
          {items.map((spend) => (
            <SpendComponent key={generateIdForItem(spend)} spend={spend} />
          ))}
        </ReactSortable>
      </table>
    </>
  )
}

export default Spends

import { MdOutlineAutorenew } from 'react-icons/md'
import { incomeForUser, sumForUser } from '../../../../lib/Calculator'
import { formatCurrency } from '../../../../lib/CurrencyFormatter'
import { UserType } from '../../../../lib/Enums/UserType'
import { useBudget } from '../../lib/Hooks/Budget'
import { useUpdateSheet } from '../../lib/Hooks/Sheet'
import { useExpenses, useIncomes } from '../../lib/Hooks/Spends'
import BalancedAllowanceColumn from './BalancedAllowanceColumn'

const BalancedAllowance = () => {
  const { data: budget } = useBudget()
  const { data: items } = useExpenses(budget!)
  const { data: incomes } = useIncomes(budget!)
  const { mutate: updateBudget } = useUpdateSheet()

  const expensesForPrimaryUser = sumForUser(items!, UserType.PRIMARY)
  const expensesForSecondaryUser = sumForUser(items!, UserType.SECONDARY)

  const incomeForPrimaryUser = incomeForUser(incomes!, UserType.PRIMARY)
  const incomeForSecondaryUser = incomeForUser(incomes!, UserType.SECONDARY)

  const allowanceForAuthor = incomeForPrimaryUser - expensesForPrimaryUser
  const allowanceForOtherUser =
    incomeForSecondaryUser - expensesForSecondaryUser

  const defaultBalancedAllowance =
    (allowanceForOtherUser + allowanceForAuthor) / 2

  const balancedAllowanceForAuthor =
    budget!.data.authorBalancedAllowance || defaultBalancedAllowance
  const balancedAllowanceForUser =
    budget!.data.userBalancedAllowance || defaultBalancedAllowance

  const maximumBalancedAllowance = allowanceForAuthor + allowanceForOtherUser

  const resetBalancedAllowance = () => {
    const newBudget = structuredClone(budget)
    newBudget!.data.authorBalancedAllowance = defaultBalancedAllowance
    newBudget!.data.userBalancedAllowance = defaultBalancedAllowance

    updateBudget(newBudget!)
  }

  const updateBalancedAllowance = (allowance: number, isAuthor: boolean) => {
    const propertyName = isAuthor
      ? 'authorBalancedAllowance'
      : 'userBalancedAllowance'
    const otherPropertyName = isAuthor
      ? 'userBalancedAllowance'
      : 'authorBalancedAllowance'

    const newBudget = structuredClone(budget)
    newBudget!.data[propertyName] = allowance
    newBudget!.data[otherPropertyName] = maximumBalancedAllowance - allowance

    updateBudget(newBudget!)
  }

  if (allowanceForAuthor === allowanceForOtherUser) {
    return null
  }
  return (
    <>
      <tr className='bg-white border-x border-b border-gray-300'>
        <td className='text-sm text-gray-900 px-6 py-4'>
          <p className='font-bold flex'>
            Balanceret rådighedsbeløb
            <MdOutlineAutorenew
              size='1.1rem'
              className='ml-2 cursor-pointer'
              title='Klik her for at nulstille det balancerede rådighedsbeløb'
              onClick={resetBalancedAllowance}
            />
          </p>
          <p className='italic font-light'>
            Balanceret rådighedsbeløb er udregnet ud fra jeres personlige
            indtægter og fælles udgifter, og er det rådighedsbeløb i begge vil
            have, hvis økonomien skal være ligeligt fordelt.
          </p>
        </td>
        <td className='text-sm text-gray-900 font-bold px-6 py-4'>
          <BalancedAllowanceColumn
            allowance={balancedAllowanceForAuthor}
            allowanceUpdated={(allowance: number) =>
              updateBalancedAllowance(allowance, true)
            }
            maximumAllowance={maximumBalancedAllowance}
          />
        </td>
        <td className='text-sm text-gray-900 font-bold px-6 py-4'>
          <BalancedAllowanceColumn
            allowance={balancedAllowanceForUser}
            allowanceUpdated={(allowance: number) =>
              updateBalancedAllowance(allowance, false)
            }
            maximumAllowance={maximumBalancedAllowance}
          />
        </td>
      </tr>
      <tr className='bg-white border-x border-b border-gray-300'>
        <td className='text-sm text-gray-900 font-light px-6 py-4'>
          Balancerede udgifter
          <p className='italic'>
            For at opnå et balanceret rådighedsbeløb, er dette beløbet I hver
            især skal overføre til den fælles budgetkonto.
          </p>
          <p className='italic'>
            Hvis beløbet er negativt, betyder det at penge skal overføres fra
            budgetkonto til lønkontoen.
          </p>
        </td>
        <td className='text-sm text-gray-900 font-light px-6 py-4'>
          {formatCurrency(incomeForPrimaryUser - balancedAllowanceForAuthor)}
        </td>
        <td className='text-sm text-gray-900 font-light px-6 py-4'>
          {formatCurrency(incomeForSecondaryUser - balancedAllowanceForUser)}
        </td>
      </tr>
    </>
  )
}

export default BalancedAllowance

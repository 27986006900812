import { useState } from 'react'
import { BudgetContext, SpendContext } from '../../../lib/Context'
import { Spend } from '../../../lib/Types'
import Headline from '../../Elements/Headline'
import { SpendType } from '../lib/Enums/SpendType'
import { useBudget } from '../lib/Hooks/Budget'
import { useExpenses, useUpdateSpend } from '../lib/Hooks/Spends'
import Welcome from '../WelcomeBudget'
import './Budget.css'
import BudgetExpenses from './BudgetExpenses'
import BudgetIncome from './BudgetIncome'
import BudgetMenu from './BudgetMenu'

const BudgetComponent = () => {
  const [viewMode, setViewMode] = useState<SpendType>(SpendType.EXPENSE)
  const urlParams = new URLSearchParams(window.location.search)

  const { data: budget } = useBudget()
  const { data: spends } = useExpenses(budget!)
  const { mutateAsync: updateSpend } = useUpdateSpend(budget!)

  /**
   * Iterate a set of spends to see if the order they are represented are identical to the order from the DB.
   *
   * @param {array} spendsToSave
   *
   * @returns {bool}
   *   Whether anything was saved or not
   */
  const saveOrder = async (spendsToSave: Spend[]): Promise<boolean> => {
    const spendsToUpdate: { [k: number]: Spend } = {}

    // Iterate all spends to update the order
    spendsToSave.forEach((item, index) => {
      if (item.order !== index) {
        item.order = index
        spendsToUpdate[item.id] = item
      }
    })

    const spendsToUpdateArr: Spend[] = Object.values(spendsToUpdate)

    await Promise.all(spendsToUpdateArr.map((item) => updateSpend(item)))

    return spendsToUpdateArr.length > 0
  }

  if (!budget) {
    return <p>No budget</p>
  }

  return (
    <>
      <Welcome
        sheet={budget}
        show={!spends!.length && !budget.is_paid && !urlParams.get('hide')}
      />
      <BudgetContext.Provider value={{ setViewMode }}>
        <SpendContext.Provider value={{ saveOrder }}>
          <Headline>{budget.name || 'Budget'}</Headline>
          {budget.description && (
            <p className='italic mb-2'>{budget.description}</p>
          )}
          <BudgetMenu viewMode={viewMode} />
          <div className='overflow-x-auto'>
            <div className='flex'>
              <div
                className={`py-2 px-5  ${viewMode === SpendType.EXPENSE ? 'underline bg-gray-300' : 'hover:underline cursor-pointer bg-gray-100'}`}
                onClick={() => setViewMode(SpendType.EXPENSE)}
              >
                Udgifter
              </div>
              <div
                className={`py-2 px-5  ${viewMode === SpendType.INCOME ? 'underline bg-gray-300' : 'hover:underline cursor-pointer bg-gray-100'}`}
                onClick={() => setViewMode(SpendType.INCOME)}
              >
                Indtægter
              </div>
            </div>
            {viewMode === SpendType.EXPENSE && <BudgetExpenses />}
            {viewMode === SpendType.INCOME && <BudgetIncome />}
          </div>
        </SpendContext.Provider>
      </BudgetContext.Provider>
    </>
  )
}

export default BudgetComponent

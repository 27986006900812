import React, { FC } from 'react'

interface Props {
  children: React.ReactNode
  className?: string
  size?: 'sm' | 'base' | 'lg' | 'xl'
}

const Text: FC<Props> = ({ children, className, size = 'base' }) => {
  return (
    <div
      className={`self-center text-${size} font-normal text-gray-900 sm:text-${size} ${className}`}
    >
      {children}
    </div>
  )
}

export default Text

import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { FaPiggyBank } from 'react-icons/fa'
import { Link, NavLink } from 'react-router-dom'
import * as UserAPI from '../../lib/API/UserAPI'
import { User } from '../../lib/Types'
import Toggle from '../AI/Toggle'

const Navigation = () => {
  const [isMenuOpen, toggleMenuOpen] = useState(false)
  const [isUserMenuOpen, toggleUserMenuOpen] = useState(false)
  const [authenticatedUser, setAuthenticatedUser] = useState<User | null>(null)

  useEffect(() => {
    // This is a work around!
    // It would be much better for caching reason, to use the useAuthenticatedUser hook.
    // But at the time of writing, defining "enabled=false" for useQuery is apparently not working, which means
    // it's impossible to have react-query not fetch on mount.Fetching the user on mount, has the side effect of
    // a white page, untill the request has resolved. This is bad for user experience.
    ;(async () => {
      const user = await UserAPI.getAuthenticatedUser()
      setAuthenticatedUser(user)
    })()
  }, [])

  const logout = async () => {
    await UserAPI.logout()

    localStorage.removeItem('token')

    window.location.href = '/'
  }

  const navigations = []

  if (authenticatedUser) {
    navigations.push({
      name: 'Overblik',
      path: '/',
    })
  }

  if (!authenticatedUser) {
    navigations.push(
      {
        name: 'Forside',
        path: 'https://privat-økonomi.dk',
      },
      {
        name: 'Log ind',
        path: '/login',
      },
    )
  }

  return (
    <div className='h-16'>
      <nav className='bg-theme-blue fixed w-full top-0 z-10'>
        <div className='px-8 mx-auto max-w-7xl'>
          <div className='flex items-center justify-between h-16'>
            <Link className='flex-shrink-0' to='/'>
              <FaPiggyBank size='2.25em' color='#F1FAEE' />
            </Link>

            <div className='hidden md:block'>
              <Toggle user={authenticatedUser} />
              {navigations.map((navigation) => (
                <NavLink
                  key={navigation.path}
                  to={navigation.path}
                  className='text-white hover:text-theme-white px-3 py-2 rounded-md text-sm font-medium'
                >
                  {navigation.name}
                </NavLink>
              ))}

              {authenticatedUser && (
                <span
                  className='text-white hover:text-theme-white cursor-pointer px-3 py-2 rounded-md text-sm font-medium'
                  onClick={() => toggleUserMenuOpen(!isUserMenuOpen)}
                >
                  Min Profil
                  {isUserMenuOpen &&
                    createPortal(
                      <div className='fixed top-12 right-10 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 z-50'>
                        <div
                          className='py-1 '
                          role='menu'
                          aria-orientation='vertical'
                          aria-labelledby='options-menu'
                        >
                          <NavLink
                            to='/profile'
                            className='block block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900'
                          >
                            <span className='flex flex-col'>
                              <span>Min Profil</span>
                            </span>
                          </NavLink>
                          <a
                            onClick={logout}
                            className='block block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900'
                            role='menuitem'
                          >
                            <span className='flex flex-col'>
                              <span>Log ud</span>
                            </span>
                          </a>
                        </div>
                      </div>,
                      document.body,
                    )}
                </span>
              )}
            </div>
            <div className='flex -mr-2 md:hidden'>
              <div className='mt-3 mr-2'>
                <Toggle user={authenticatedUser} />
              </div>
              <button
                onClick={() => toggleMenuOpen(!isMenuOpen)}
                className='text-white inline-flex items-center justify-center p-2 rounded-md focus:outline-none'
              >
                <svg
                  width='20'
                  height='20'
                  fill='currentColor'
                  className='w-8 h-8'
                  viewBox='0 0 1792 1792'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path d='M1664 1344v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45z'></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
        {isMenuOpen &&
          createPortal(
            <nav className='md:hidden bg-theme-blue fixed w-full top-14 z-10'>
              <div className='px-2 pt-2 pb-3 space-y-1 sm:px-3 z-10'>
                {navigations.map((navigation) => (
                  <NavLink
                    key={navigation.path}
                    to={navigation.path}
                    className='text-white hover:text-theme-white block px-3 py-2 rounded-md text-base font-medium'
                  >
                    {navigation.name}
                  </NavLink>
                ))}
                {authenticatedUser && (
                  <>
                    <NavLink
                      to='/profile'
                      className='text-white hover:text-theme-white block px-3 py-2 rounded-md text-base font-medium'
                    >
                      Min Profil
                    </NavLink>
                    <a
                      onClick={logout}
                      className='text-white hover:text-theme-white block px-3 py-2 rounded-md text-base font-medium'
                    >
                      Log ud
                    </a>
                  </>
                )}
              </div>
            </nav>,
            document.body,
          )}
      </nav>
    </div>
  )
}

export default Navigation

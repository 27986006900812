import { FC, useState } from 'react'
import { MdArrowDropDown, MdArrowRight } from 'react-icons/md'
import { getItemAmountForPeriod } from '../../../lib/Calculator'
import { formatCurrency } from '../../../lib/CurrencyFormatter'
import { Spend } from '../../../lib/Types'
import Headline from '../../Elements/Headline'
import Text from '../../Elements/Text'
import { SpendFrequency } from '../lib/Enums/SpendFrequency'

type Props = {
  spends: Spend[]
}

type BalanceOverviewSpend = {
  title: string
  amount: number
}

const BalanceOverview: FC<Props> = ({ spends }) => {
  const [showSpends, toggleShowSpends] = useState<string[]>([])

  const isCategory = (spend: Spend) => {
    return spend.children && spend.children.length > 0
  }

  const getPaymentInterval = (frequency: SpendFrequency): number => {
    switch (frequency) {
      case SpendFrequency.MONTHLY:
        return 1
      case SpendFrequency.BIMONTHLY:
        return 2
      case SpendFrequency.QUARTERLY:
        return 3
      case SpendFrequency.BIYEARLY:
        return 6
      default:
        return 12
    }
  }

  const getPaymentMonths = (
    frequency: SpendFrequency,
    accountingMonth: number,
  ): number[] => {
    let paymentMonths = [accountingMonth]

    switch (frequency) {
      case SpendFrequency.MONTHLY: {
        paymentMonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        break
      }
      case SpendFrequency.BIMONTHLY: {
        if ([1, 3, 5, 7, 9, 11].includes(accountingMonth)) {
          paymentMonths = [1, 3, 5, 7, 9, 11]
        } else if ([2, 4, 6, 8, 10, 12].includes(accountingMonth)) {
          paymentMonths = [2, 4, 6, 8, 10, 12]
        }
        break
      }
      case SpendFrequency.QUARTERLY: {
        if ([1, 4, 7, 10].includes(accountingMonth)) {
          paymentMonths = [1, 4, 7, 10]
        } else if ([2, 5, 8, 11].includes(accountingMonth)) {
          paymentMonths = [2, 5, 8, 11]
        } else if ([3, 6, 9, 12].includes(accountingMonth)) {
          paymentMonths = [3, 6, 9, 12]
        }
        break
      }
      case SpendFrequency.BIYEARLY: {
        if ([1, 7].includes(accountingMonth)) {
          paymentMonths = [1, 7]
        } else if ([2, 8].includes(accountingMonth)) {
          paymentMonths = [2, 8]
        } else if ([3, 9].includes(accountingMonth)) {
          paymentMonths = [3, 9]
        } else if ([4, 10].includes(accountingMonth)) {
          paymentMonths = [4, 10]
        } else if ([5, 11].includes(accountingMonth)) {
          paymentMonths = [5, 11]
        } else if ([6, 12].includes(accountingMonth)) {
          paymentMonths = [6, 12]
        }
        break
      }
      default:
        break
    }

    return paymentMonths
  }

  const getSpendsWithAccountingDateInMonth = (month: number) => {
    const spendsFound: BalanceOverviewSpend[] = []

    spends
      .filter((spend: Spend) => !isCategory(spend))
      .forEach((spend: Spend) => {
        const accountingMonth = new Date(spend.accounting_date).getMonth() + 1
        const frequency = spend.amounts.at(-1)!.frequency!

        const paymentMonths = getPaymentMonths(frequency, accountingMonth)

        // Find the first payment month before the current month. If the month is
        // a payment month, use that.
        let nearestPaymentMonth = 0
        for (let i = paymentMonths.length - 1; i >= 0; i--) {
          if (paymentMonths[i] <= month) {
            nearestPaymentMonth = paymentMonths[i]
            break
          }
        }

        // If no nearest payment month was found, it means that the current month
        // was before the first payment month, meaning the nearest payment month
        // is then the last payment month in the year (and in the array)
        if (!nearestPaymentMonth) {
          nearestPaymentMonth = paymentMonths.at(-1)!
        }

        const amountPerMonth = getItemAmountForPeriod(
          spend,
          SpendFrequency.MONTHLY,
        )

        const monthSpan =
          month < nearestPaymentMonth
            ? month + 12 - nearestPaymentMonth
            : month - nearestPaymentMonth

        const multiplier =
          monthSpan === 0 ? getPaymentInterval(frequency) : monthSpan

        const amount = amountPerMonth * multiplier

        if (amount) {
          let title = spend.title

          if (spend.parent_id) {
            const parentSpend = spends.find(
              (parentSpend) => parentSpend.id === spend.parent_id,
            )
            if (parentSpend) {
              title = `${parentSpend.title} - ${title}`
            }
          }
          spendsFound.push({ title, amount })
        }
      })

    spendsFound.sort((a, b) => a.title.localeCompare(b.title))

    return spendsFound
  }

  const summarizeSpends = (spends: BalanceOverviewSpend[]) => {
    return spends.reduce((acc, spend) => acc + spend.amount, 0)
  }

  const periods = [
    {
      1: 'Januar',
      2: 'Februar',
      3: 'Marts',
      4: 'April',
    },
    {
      5: 'Maj',
      6: 'Juni',
      7: 'Juli',
      8: 'August',
    },
    {
      9: 'September',
      10: 'Oktober',
      11: 'November',
      12: 'December',
    },
  ]

  return (
    <>
      <Headline className='mb-5'>Balanceoverblik</Headline>
      <>
        <Text>
          Her kan finder et overblik over den nødvenige balance for hver måned.
        </Text>
        <Text>
          Beløbet for hver måned er baseret på de udgifter, der er sat op i
          budgettet, og beløbet bør være tilgængelig på din budgetkonto pr. 1. i
          måneden.
        </Text>
        <div className='mt-5'>
          {periods.map((months, index) => (
            <div key={index}>
              {Object.entries(months).map(([month, name]) => (
                <div className='border-b-2 py-2' key={month}>
                  <div
                    className='flex items-center cursor-pointer'
                    onClick={() => {
                      if (showSpends.includes(month)) {
                        toggleShowSpends(
                          showSpends.filter((show) => show !== month),
                        )
                      } else {
                        toggleShowSpends([...showSpends, month])
                      }
                    }}
                  >
                    <div>
                      {showSpends.includes(month) && (
                        <MdArrowDropDown
                          size='1.25rem'
                          className='float-left'
                        />
                      )}
                      {!showSpends.includes(month) && (
                        <MdArrowRight size='1.25rem' className='float-left' />
                      )}
                    </div>
                    <Headline size='base' className='grow'>
                      {name}
                    </Headline>
                    <Text size='base' className='mt-1 strong'>
                      {formatCurrency(
                        summarizeSpends(
                          getSpendsWithAccountingDateInMonth(
                            parseInt(month, 10),
                          ),
                        ),
                      )}
                    </Text>
                  </div>
                  {showSpends.includes(month) && (
                    <div className='mb-2 ml-5'>
                      {getSpendsWithAccountingDateInMonth(
                        parseInt(month, 10),
                      ).map((spend, index) => (
                        <div className='flex items-center' key={index}>
                          <Text size='sm' className='grow'>
                            {spend.title}
                          </Text>
                          <Text size='sm'>{formatCurrency(spend.amount)}</Text>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      </>
    </>
  )
}

export default BalanceOverview

import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { Budget } from '../../../../lib/Types'
import Headline from '../../../Elements/Headline'
import Text from '../../../Elements/Text'
import Button from '../../../Formik/Button'
import { AccountingType } from '../../lib/Enums/AccountingType'
import { AccountingViewMode } from '../../lib/Enums/AccountingViewMode'
import { useCreateReconciliationAccounting } from '../../lib/Hooks/Accounting'

type Props = {
  budget: Budget
}
const ReconciliationInfo: FC<Props> = ({ budget }) => {
  const { mutateAsync: createAccounting, isPending: reconciliationLoading } =
    useCreateReconciliationAccounting(budget)

  const navigate = useNavigate()

  const onSubmit = async () => {
    const accounting = await createAccounting({
      type: AccountingType.YEAR,
      budget: 0,
      view_mode: AccountingViewMode.CATEGORIES,
      dedicated_account: false,
      related_budget: budget,
    })

    return navigate(`/accounting/${accounting.id}/`)
  }
  return (
    <>
      <Headline className='mb-5'>Budgetafstemning</Headline>
      <Text className='mb-5'>
        Budgetafstemning er, når du tjekker, om dit faktiske forbrug passer med
        det, du havde budgetteret med at bruge. Det hjælper dig med at holde
        styr på din økonomi og sikrer, at du justerer dit budget i forhold til
        dit reele forbrug.
      </Text>
      <img src='https://privat-økonomi.dk/images/budget/budget-reconciliation.png' />
      <Text className='mt-5 mb-5'>
        Du kan automatisk klargøre et regnskab til budgetafstemning. Når du
        bruger denne funktion, opretter vi et årsregnskab hvor kategorierne
        passer med udgifterne i dit budget. Det gør det nemt og hurtigt at komme
        i gang med at afstemme dit budget.
      </Text>
      <Button onClick={onSubmit} loading={reconciliationLoading}>
        Klargør regnskab nu
      </Button>
    </>
  )
}

export default ReconciliationInfo

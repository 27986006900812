import React, { FC } from 'react'

interface Props {
  children: React.ReactNode
  size?: '5xl' | '4xl' | '3xl' | '2xl' | 'xl' | 'lg' | 'base'
  className?: string
}

const Headline: FC<Props> = ({ children, size = 'xl', className }) => {
  const getSizeClass = () => {
    switch (size) {
      case '5xl':
        return 'text-5xl'
      case '4xl':
        return 'text-4xl'
      case '3xl':
        return 'text-3xl'
      case '2xl':
        return 'text-2xl'
      case 'xl':
        return 'text-xl'
      case 'lg':
        return 'font-bold text-lg'
      case 'base':
        return 'text-base'
    }
    return 'text-base'
  }

  return (
    <div className={`${getSizeClass()} text-gray-800 ${className}`}>
      {children}
    </div>
  )
}

export default Headline

import { FC } from 'react'
import { MdFormatListBulleted, MdGridView } from 'react-icons/md'
import { Accounting } from '../../../lib/Types'
import Button from '../../Formik/Button'

import { AccountingViewMode } from '../lib/Enums/AccountingViewMode'
import { useUpdateSheet } from '../lib/Hooks/Sheet'

interface Props {
  accounting: Accounting
}

const ViewMode: FC<Props> = ({ accounting }) => {
  const { mutate: updateSheet } = useUpdateSheet()
  return (
    <Button
      theme='gray'
      className='ml-2'
      onClick={() => {
        const newViewMode =
          accounting.view_mode === AccountingViewMode.CATEGORIES
            ? AccountingViewMode.SPENDS
            : AccountingViewMode.CATEGORIES

        const newAccounting = structuredClone(accounting)
        newAccounting.view_mode = newViewMode

        updateSheet(newAccounting)
      }}
    >
      <div className='flex'>
        {accounting.view_mode === 'categories' && (
          <>
            <MdFormatListBulleted size='1.5rem' className='md:mr-2' />
            <span>Listevisning</span>
          </>
        )}
        {accounting.view_mode === 'spends' && (
          <>
            <MdGridView size='1.5rem' className='md:mr-2' />
            <span>Kategorivisning</span>
          </>
        )}
      </div>
    </Button>
  )
}

export default ViewMode

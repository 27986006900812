import { FC, useContext, useEffect, useState } from 'react'
import { ReactSortable } from 'react-sortablejs'
import { SpendContext } from '../../../lib/Context'
import { generateIdForItem } from '../../../lib/IdGenerator'
import { Spend } from '../../../lib/Types'
import { useBudget } from '../lib/Hooks/Budget'
import SpendRow from './SpendRow'

interface Props {
  spend: Spend
}
const SpendComponent: FC<Props> = ({ spend }) => {
  const { data: budget } = useBudget()
  const [children, setChildren] = useState(spend.children)
  const [showChildren, toggleShowChildren] = useState(false)
  const { saveOrder } = useContext(SpendContext)

  useEffect(() => {
    setChildren(spend.children)
  }, [spend.children])

  return (
    <>
      <tr className='bg-gray-100 border-x border-gray-300 draggable'>
        <td className='draggable-wrapper'>
          <SpendRow
            spend={spend}
            showChildren={showChildren}
            toggleChildren={(state?: boolean) =>
              toggleShowChildren(state || !showChildren)
            }
          />
          {showChildren && children.length > 0 && (
            <table
              className={`min-w-full table-budget ${budget!.user ? 'col-5' : ''}`}
            >
              <ReactSortable
                tag='tbody'
                group={`category-${spend.id}`}
                list={children}
                delay={3000}
                delayOnTouchOnly={true}
                setList={(reorderedChildren, _, { dragging }) => {
                  if (!dragging) {
                    return
                  }

                  saveOrder(reorderedChildren)
                  setChildren(reorderedChildren)
                }}
                dragClass='list-selected'
                ghostClass='list-placeholder'
                draggable='.draggable-spend'
              >
                {children.map((child) => (
                  <tr
                    key={generateIdForItem(child)}
                    className='bg-gray-100 border-b border-gray-300 draggable-spend'
                  >
                    <td className='draggable-wrapper'>
                      <SpendRow spend={child} isChild />
                    </td>
                  </tr>
                ))}
              </ReactSortable>
            </table>
          )}
        </td>
      </tr>
    </>
  )
}

export default SpendComponent

import { useMutation } from '@tanstack/react-query'
import { SheetType } from '../../Components/Sheets/lib/Enums/SheetType'

import * as PaymentAPI from '../API/PaymentAPI'

export const usePayment = () => {
  return useMutation({
    mutationFn: (sheet: { sheetType: SheetType; id: number }) =>
      PaymentAPI.payment(sheet.sheetType, sheet.id),
  })
}

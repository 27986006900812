import { useField } from 'formik'
import { FC } from 'react'

interface Props {
  name: string
  placeholder: string
  className?: string
}
const Textarea: FC<Props> = ({ name, placeholder, className }) => {
  const [field, meta] = useField({ name, placeholder })

  return (
    <div>
      <label className='text-gray-700'>
        <textarea
          className={`flex-1 w-full px-4 py-2 text-base text-gray-700 placeholder-gray-400 bg-white border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent ${className}`}
          placeholder={placeholder}
          rows={5}
          cols={40}
          {...field}
        ></textarea>
      </label>
      {meta.touched && meta.error && (
        <div className='absolute -bottom-2 right-2 bg-white px-2 rounded-md text-xs text-red-400'>
          {meta.error}
        </div>
      )}
    </div>
  )
}

export default Textarea

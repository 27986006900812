import Config from '../../../lib/Config'
import Headline from '../../Elements/Headline'
import Text from '../../Elements/Text'

const DownloadPaywall = () => {
  return (
    <>
      <Headline>Download dit budget</Headline>
      <Text className='my-6'>
        For at hente dit budget, skal du have et abonnement. Det koster {Config.PRICE_BUDGET},- om året, og giver dig adgang til alle premium funktioner.
      </Text>
    </>
  )
}

export default DownloadPaywall

import { FC, useContext, useState } from 'react'
import { incomeForUser, sumForUser } from '../../../../lib/Calculator'
import { formatCurrency } from '../../../../lib/CurrencyFormatter'
import { UserType } from '../../../../lib/Enums/UserType'
import { Spend } from '../../../../lib/Types'
import Headline from '../../../Elements/Headline'
import Text from '../../../Elements/Text'
import { useBudget } from '../../lib/Hooks/Budget'

import { MdModeEditOutline } from 'react-icons/md'
import { SidebarContext } from '../../../../lib/Context'
import { Modal } from '../.././../Elements/Modal'
import { SpendType } from '../../lib/Enums/SpendType'
import { useIncomes } from '../../lib/Hooks/Spends'
import BalancedAllowance from './BalancedAllowance'
import BalancedAllowanceHelpText from './BalancedAllowanceHelpText'

type Props = {
  items: Spend[]
  setViewMode: (type: SpendType) => void
}

const Allowance: FC<Props> = ({ items, setViewMode }) => {
  const { data: budget } = useBudget()
  const { data: spends } = useIncomes(budget!)
  const [info, setInfo] = useState<string | null>(null)
  const { showSidebar } = useContext(SidebarContext)

  const getUserName = (user: UserType) => {
    if (user === UserType.PRIMARY) {
      return budget!.author.name || budget!.author.email.split('@')[0]
    }
    return budget!.user!.name || budget!.user!.email.split('@')[0]
  }

  const expensesForPrimaryUser = sumForUser(items, UserType.PRIMARY)
  const expensesForSecondaryUser = sumForUser(items, UserType.SECONDARY)

  const incomeForPrimaryUser = incomeForUser(spends!, UserType.PRIMARY)
  const incomeForSecondaryUser = incomeForUser(spends!, UserType.SECONDARY)

  return (
    <>
      <Headline className='mb-2 mt-5'>Rådighedsbeløb</Headline>
      <Text className='mb-5'>
        Herunder kan se beregningen af {budget!.user ? 'jeres' : 'dit'}{' '}
        rådighedsbeløb. Rådighedsbeløbet er de penge {budget!.user ? 'I' : 'du'}{' '}
        har tilbage, når alle {budget!.user ? 'jeres' : 'dine'} faste udgifter
        er betalt.
      </Text>
      <table className='min-w-full mb-5'>
        <tbody>
          <tr className='bg-table-sub-header border-y border-x border-gray-300'>
            <th className='text-sm font-medium text-gray-900 px-6 py-4 text-left'>
              Type
            </th>
            <th className='text-sm font-medium text-gray-900 px-6 py-4 text-left'>
              {getUserName(UserType.PRIMARY)}
            </th>
            {budget!.user && (
              <th className='text-sm font-medium text-gray-900 px-6 py-4 text-left'>
                {getUserName(UserType.SECONDARY)}
              </th>
            )}
          </tr>
          <tr className='bg-white border-x border-b border-gray-300'>
            <td className='text-sm text-gray-900 font-light px-6 py-4 flex'>
              Indtægter
              <MdModeEditOutline
                className='ml-1 cursor-pointer'
                size='1.1rem'
                onClick={() => {
                  setViewMode(SpendType.INCOME)
                  showSidebar(null)
                }}
              />
            </td>
            <td className='text-sm text-gray-900 font-light px-6 py-4'>
              {formatCurrency(incomeForPrimaryUser)}
            </td>
            {budget!.user && (
              <td className='text-sm text-gray-900 font-light px-6 py-4'>
                {formatCurrency(incomeForSecondaryUser)}
              </td>
            )}
          </tr>
          <tr className='bg-white border-x border-b border-gray-300'>
            <td className='text-sm text-gray-900 font-light px-6 py-4'>
              Udgifter
              <p className='italic'>
                Beløbet {budget!.user ? 'I hver' : 'du'} skal overføre til{' '}
                {budget!.user ? 'den fælles budgetkonto' : 'din budgetkonto'}.
              </p>
            </td>
            <td className='text-sm text-gray-900 font-light px-6 py-4'>
              {formatCurrency(expensesForPrimaryUser)}
            </td>
            {budget!.user && (
              <td className='text-sm text-gray-900 font-light px-6 py-4'>
                {formatCurrency(expensesForSecondaryUser)}
              </td>
            )}
          </tr>
          <tr className='bg-white border-x border-b border-gray-300'>
            <td className='text-sm text-gray-900 font-bold px-6 py-4'>
              Rådighedsbeløb
            </td>
            <td className='text-sm text-gray-900 font-bold px-6 py-4'>
              {formatCurrency(incomeForPrimaryUser - expensesForPrimaryUser)}
            </td>
            {budget!.user && (
              <td className='text-sm text-gray-900 font-bold px-6 py-4'>
                {formatCurrency(
                  incomeForSecondaryUser - expensesForSecondaryUser,
                )}
              </td>
            )}
          </tr>
          {budget!.user && <BalancedAllowance />}
        </tbody>
      </table>
      {budget!.user && <BalancedAllowanceHelpText />}
      <Modal onHide={() => setInfo(null)} show={info !== null}>
        {info}
      </Modal>
    </>
  )
}

export default Allowance

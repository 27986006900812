import { ReactNode, type FC } from 'react'

type Props = {
  children: ReactNode
  className?: string
  isCompleted?: boolean
  completionLevel?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9
  backgroundColor: string
}
const Category: FC<Props> = ({
  children,
  className,
  completionLevel,
  isCompleted,
  backgroundColor,
}) => {
  return (
    <div
      className={`${className} ${isCompleted ? 'bg-emerald-300' : backgroundColor} overflow-hidden w-full md:w-1/3 p-4 md:min-h-[250px] relative border-y`}
    >
      <>
        {!!completionLevel && (
          <>
            <div
              className={`absolute h-full bg-theme-cyan top-0 left-0 w-[${completionLevel - 20}%]`}
            />
            <div
              className={`absolute w-full h-full bg-theme-cyan top-0 left-0 rotate-45 origin-right translate-y-[88px] -translate-x-[${100 - completionLevel * 10}%]`}
            />
          </>
        )}
        <div className='relative h-full'>{children}</div>
      </>
    </div>
  )
}

export default Category

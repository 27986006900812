import { FC } from 'react'
import { MdOpenInNew } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { formatCurrency } from '../../../lib/CurrencyFormatter'
import { Budget } from '../../../lib/Types'
import Headline from '../../Elements/Headline'
import Text from '../../Elements/Text'
import Button from '../../Formik/Button'
import { useBudgetAmounts } from '../lib/Hooks/Budget'
import BudgetDevelopmentChart from './BudgetDevelopmentChart'

interface Props {
  budget: Budget
}

const Card: FC<Props> = ({ budget }) => {
  const { data: budgetAmounts } = useBudgetAmounts(budget)

  return (
    <div className='bg-gray-100 p-4 mt-4'>
      <Link to={`/budget/${budget.id}`}>
        <div className='flex justify-between items-start'>
          <Headline size='base' className='mb-4'>
            <strong>{budget.name || `Budget`}</strong>
          </Headline>
          <Button theme='gray'>
            <div className='flex'>
              <MdOpenInNew size='1.25rem' className='mr-2' />
              Åbn
            </div>
          </Button>
        </div>
        <Text size='base'>Udgifter i alt</Text>
        <Headline size='5xl' className='mb-2'>
          kr. {formatCurrency(budgetAmounts?.at(-1)?.amount || 0, false)}
        </Headline>

        <div className='mt-6'>
          <BudgetDevelopmentChart budget={budget} showEmptyWarning={false} />
        </div>
      </Link>
    </div>
  )
}

export default Card

import { Form, Formik } from 'formik'
import { MdEmail } from 'react-icons/md'
import { useActionData, useLocation, useSubmit } from 'react-router-dom'
import * as Yup from 'yup'
import Headline from '../Components/Elements/Headline'
import Text from '../Components/Elements/Text'
import Button from '../Components/Formik/Button'
import Input from '../Components/Formik/Input'
import * as UserAPI from '../lib/API/UserAPI'

export const login = async ({ params, request }) => {
  const formData = await request.formData()

  UserAPI.sendMagicLink(formData.get('email'))

  return true
}

export default function LoginPage() {
  const isMagicLinkSent = useActionData()
  const { state } = useLocation()
  const submit = useSubmit()

  if (isMagicLinkSent) {
    return (
      <>
        <Headline>
          Et magisk log ind link er på vej til din e-mail adresse. 🪄
        </Headline>
        <Text className='mt-4'>
          Vi har sendt dig en e-mail hvori du finder et link der vil logge dig
          ind.
        </Text>
      </>
    )
  }

  return (
    <>
      <Headline>{state?.message || 'Log ind'}</Headline>
      <Text>
        For at få adgang til dit regnskab skal du logge ind på din konto.
        Indtast din e-mail, så sender vi et log ind link. Hvis du ikke har en
        konto, opretter vi en til dig.
      </Text>
      <div className='mt-8'>
        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .matches(
                /^[A-ZÆØÅ0-9._%+-]+@[A-ZÆØÅ0-9.-]+.[A-ZÆØÅ]{2,}$/i,
                'Ugyldig e-mail adresse',
              )
              .required('Påkrævet'),
          })}
          onSubmit={(values) => {
            submit(values, { method: 'post' })
          }}
        >
          <Form>
            <div className='flex flex-col mb-6'>
              <Input
                name='email'
                placeholder='Indtast din e-mail adresse'
                icon={<MdEmail />}
              />
            </div>
            <div className='flex w-full'>
              <Button type='submit'>Log ind</Button>
            </div>
          </Form>
        </Formik>
      </div>
    </>
  )
}

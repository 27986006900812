import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import * as TaskAPI from '../API/TaskAPI'
import { TaskType } from '../Enums/TaskType'

interface UseTaskReslut {
  data?: TaskType
}

export const useTask = (): UseTaskReslut => {
  return useQuery({
    queryKey: ['task'],
    queryFn: () => TaskAPI.fetchCurrentTask(),
    staleTime: Infinity,
  })
}

export const useSkipTask = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (task: TaskType) => TaskAPI.markAsSkipped(task),
    onSuccess: (data) => {
        queryClient.invalidateQueries({ queryKey: ['task'] })
    }
  })
}

import { Form, Formik } from 'formik'
import { useContext } from 'react'
import { SheetContext } from '../../../lib/Context'
import Text from '../../Elements/Text'
import Button from '../../Formik/Button'
import Checkbox from '../../Formik/Checkbox'
import Input from '../../Formik/Input'
import Select from '../../Formik/Select'
import { AccountingType } from '../lib/Enums/AccountingType'
import { useUpdateSheet } from '../lib/Hooks/Sheet'

const Configuration = () => {
  const { sheet: accounting, updateSheet } = useContext(SheetContext)
  const { mutate: saveSheet, isPending: isSaving } = useUpdateSheet({
    onSuccess: updateSheet,
  })

  const submit = async (
    type: AccountingType,
    budget: number,
    dedicatedAccount: boolean,
    startingBalance: number,
  ) => {
    const newSheet = structuredClone(accounting)
    newSheet.type = type
    newSheet.budget = budget
    newSheet.dedicated_account = dedicatedAccount
    newSheet.starting_balance = startingBalance

    saveSheet(newSheet)
  }

  const getBudgetType = () => {
    switch (accounting.type) {
      case AccountingType.WEEK:
        return 'det ugelige'
      case AccountingType.MONTH:
        return 'det månedlige'
      case AccountingType.YEAR:
        return 'det årlige'
      default:
        return ''
    }
  }

  return (
    <div className='my-5'>
      <Text className='mb-5'>
        Du kan her ændre specifikke indstillinger for det valgte regnskab.
      </Text>
      <Formik
        initialValues={{
          type: accounting.type || AccountingType.REGULAR,
          budget: accounting.budget || '',
          dedicatedAccount: accounting.dedicated_account,
          startingBalance: accounting.starting_balance,
        }}
        onSubmit={({ type, budget, dedicatedAccount, startingBalance }) =>
          submit(
            type,
            budget === '' ? null : budget,
            dedicatedAccount,
            startingBalance,
          )
        }
      >
        {({ values: { dedicatedAccount } }) => (
          <Form>
            <p className='mb-2'>Type</p>
            <Select name='type' className='mb-3'>
              <option value={AccountingType.REGULAR}>Regnskab</option>
              <option value={AccountingType.YEAR}>Årsregnskab</option>
              <option value={AccountingType.MONTH}>Månedsregnskab</option>
              <option value={AccountingType.WEEK}>Ugeregnskab</option>
            </Select>
            <p className='mb-2'>Budget</p>
            <Input
              name='budget'
              placeholder={`Angiv ${getBudgetType()} budget for regnskabet`}
              className='mb-5'
            />
            <Checkbox name='dedicatedAccount' className='mb-5'>
              Jeg har en dedikeret konto i banken til dette regnskab
            </Checkbox>
            {dedicatedAccount && (
              <div className='mb-5'>
                <p className='mb-2'>Saldo</p>
                <Input
                  type='number'
                  name='startingBalance'
                  placeholder='Angiv den nuværende saldo'
                />
              </div>
            )}
            <Button type='submit' className='w-full' loading={isSaving}>
              Gem
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default Configuration

import { ReactNode, Suspense, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import LoadingSpinner from '../Components/Elements/LoadingSpinner'
import Navigation from '../Components/Navigation/Navigation'
import ResetPage from '../Components/ResetPage'
import Sidebar from '../Components/Sidebar'
import { SidebarContext } from '../lib/Context'

function BasePage() {
  const [sidebarContent, setSidebarContent] = useState<ReactNode | null>()

  const escapeKeyClose = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setSidebarContent(null)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', escapeKeyClose)
    return () => document.removeEventListener('keydown', escapeKeyClose)
  }, [])

  return (
    <div className='relative bg-white min-h-screen'>
      <SidebarContext.Provider
        value={{
          showSidebar: (content: ReactNode) => {
            setSidebarContent(content)
          },
          isSidebarOpen: !!sidebarContent,
        }}
      >
        <ResetPage />
        <Navigation />
        <div className='py-4 px-8 mx-auto max-w-7xl'>
          <Suspense fallback={<LoadingSpinner showText={true} />}>
            <Outlet />
            <Sidebar content={sidebarContent} />
          </Suspense>
        </div>
      </SidebarContext.Provider>
    </div>
  )
}

export default BasePage

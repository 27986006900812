import { FC, useState } from 'react'
import { MdArrowDropDown, MdArrowRight } from 'react-icons/md'
import { getItemAmountForPeriod } from '../../../lib/Calculator'
import { Spend, SpendAmountCreate } from '../../../lib/Types'
import { SpendFrequency } from '../lib/Enums/SpendFrequency'
import { useBudget } from '../lib/Hooks/Budget'
import { useUpdateSpend } from '../lib/Hooks/Spends'
import AddSpend from './AddSpend'
import SpendColumn from './SpendColumn'
import SpendUserColumns from './SpendUserColumns'
import TitleColumn from './TitleColumn'

interface Props {
  spend: Spend
  isChild?: boolean
  showChildren?: boolean
  toggleChildren?: (state?: boolean) => void
}

/**
 * Return the sum for the period
 */
export const getCatoryAmountForPeriod = (
  spend: Spend,
  frequency: SpendFrequency.MONTHLY | SpendFrequency.YEARLY,
): number => {
  let amount = getItemAmountForPeriod(spend, frequency)

  if (spend.children) {
    spend.children.forEach((child) => {
      amount += getItemAmountForPeriod(child, frequency)
    })
  }

  return amount
}

const SpendRow: FC<Props> = ({
  spend,
  isChild = false,
  showChildren,
  toggleChildren,
}) => {
  const { data: budget } = useBudget()
  const [showAddSpend, toggleAddSpend] = useState(false)
  const [activeColumn, setActiveColumn] = useState<SpendFrequency | null>()
  const { mutate: updateSpend } = useUpdateSpend(budget!)

  const hasChildren = () => {
    return spend.children && spend.children.length > 0
  }

  const saveAmount = async (
    amount: number,
    frequency: SpendFrequency.YEARLY | SpendFrequency.MONTHLY,
  ) => {
    const latestAmount = spend.amounts.at(-1)!
    let newAmount = amount

    // Convert the new amount to the frequency of the latest amount
    switch (latestAmount!.frequency) {
      case SpendFrequency.MONTHLY: {
        if (frequency === SpendFrequency.YEARLY) {
          newAmount = amount / 12
        }
        break
      }
      case SpendFrequency.BIMONTHLY: {
        if (frequency === SpendFrequency.YEARLY) {
          newAmount = amount / 6
        } else if (frequency === SpendFrequency.MONTHLY) {
          newAmount = amount * 2
        }
        break
      }
      case SpendFrequency.QUARTERLY: {
        if (frequency === SpendFrequency.YEARLY) {
          newAmount = amount / 4
        } else if (frequency === SpendFrequency.MONTHLY) {
          newAmount = amount * 3
        }
        break
      }
      case SpendFrequency.BIYEARLY: {
        if (frequency === SpendFrequency.YEARLY) {
          newAmount = amount / 2
        } else if (frequency === SpendFrequency.MONTHLY) {
          newAmount = amount * 6
        }
        break
      }
      case SpendFrequency.YEARLY: {
        if (frequency === SpendFrequency.MONTHLY) {
          newAmount = amount * 12
        }
        break
      }
    }

    if (newAmount !== latestAmount.amount) {
      const newAmounts = structuredClone(spend.amounts) as SpendAmountCreate[]
      newAmounts.push({
        formula: newAmount.toString(),
        frequency: latestAmount.frequency,
      })

      updateSpend({ ...spend, amounts: newAmounts })
    }

    setActiveColumn(null)
  }

  return (
    <>
      {!isChild && (
        <AddSpend
          parent={spend}
          toggleHide={() => toggleAddSpend(false)}
          show={showAddSpend}
          saved={() => toggleChildren && toggleChildren(true)}
        />
      )}
      <table
        className={`min-w-full table-budget ${budget!.user ? 'col-5' : ''}`}
      >
        <tbody>
          <tr
            className={`bg-white ${isChild ? '' : 'border-b'} border-gray-300`}
          >
            <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
              {hasChildren() && (
                <div className='cursor-pointer'>
                  {showChildren && (
                    <MdArrowDropDown
                      size='1.25rem'
                      className='float-left'
                      onClick={() => toggleChildren && toggleChildren()}
                    />
                  )}
                  {!showChildren && (
                    <MdArrowRight
                      size='1.25rem'
                      className='float-left'
                      onClick={() => toggleChildren && toggleChildren()}
                    />
                  )}
                </div>
              )}
              <TitleColumn
                spend={spend}
                toggleAddSpend={() => toggleAddSpend(true)}
                toggleChildren={() =>
                  hasChildren() && toggleChildren && toggleChildren()
                }
                isChild={isChild}
              />
            </td>
            <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
              <SpendColumn
                amount={
                  showChildren
                    ? getItemAmountForPeriod(spend, SpendFrequency.YEARLY)
                    : getCatoryAmountForPeriod(spend, SpendFrequency.YEARLY)
                }
                isActive={activeColumn === SpendFrequency.YEARLY}
                activate={() => setActiveColumn(SpendFrequency.YEARLY)}
                save={(amount: number) =>
                  saveAmount(amount, SpendFrequency.YEARLY)
                }
              />
            </td>
            <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
              <SpendColumn
                amount={
                  showChildren
                    ? getItemAmountForPeriod(spend, SpendFrequency.MONTHLY)
                    : getCatoryAmountForPeriod(spend, SpendFrequency.MONTHLY)
                }
                isActive={activeColumn === SpendFrequency.MONTHLY}
                activate={() => setActiveColumn(SpendFrequency.MONTHLY)}
                save={(amount: number) =>
                  saveAmount(amount, SpendFrequency.MONTHLY)
                }
              />
            </td>
            {budget!.user && (
              <SpendUserColumns
                spend={spend}
                isSpendExpanded={!!showChildren}
              />
            )}
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default SpendRow

import { ReactNode, useContext, useRef, useState } from 'react'
import { MdMoreVert } from 'react-icons/md'
import useOnClickOutside from 'use-onclickoutside'
import { SidebarContext } from '../../../lib/Context'
import Button from '../../Formik/Button'
import CategoriesForm from '../Configuration/CategoriesForm'
import Configuration from '../Configuration/Configuration'
import Share from '../Configuration/Share'
import { AccountingType } from '../lib/Enums/AccountingType'
import { useAccounting } from '../lib/Hooks/Accounting'
import MenuButton from '../MenuButton'
import MenuLink from '../MenuLink'
import AccountingDevelopment from './AccountingDevelopment'
import AccountConfiguration from './Configuration'
import Expenses from './Expenses'
import ViewMode from './ViewMode'

const AccountingMenu = () => {
  const [isActive, toggleActive] = useState(false)
  const { showSidebar } = useContext(SidebarContext)

  const { data: accounting } = useAccounting()
  const ref = useRef(null)

  const openSidebar = (content: ReactNode) => {
    showSidebar(content)
    toggleActive(false)
  }

  useOnClickOutside(ref, () => toggleActive(false))

  return (
    <div className='flex flex-wrap justify-end mb-2 mt-2 sm:mt-0'>
      <div className='relative' ref={ref}>
        <Button
          theme='gray'
          className={`ml-2 cursor-pointer ${isActive ? 'rounded-b-none' : ''}`}
          onClick={() => toggleActive(!isActive)}
        >
          <MdMoreVert size='1.5rem' />
        </Button>
        {isActive && (
          <div className='absolute top-10 left-2 bg-white border-gray-300 border-2 px-4 pt-4 z-10'>
            <div className='sm:hidden'>
              <MenuLink
                title='Del budgettet'
                icon='MdShare'
                action={() => openSidebar(<Share />)}
              />
            </div>
            <div className='md:hidden'>
              <MenuLink
                title='Kategorier'
                icon='MdCategory'
                action={() =>
                  openSidebar(<CategoriesForm sheet={accounting!} />)
                }
              />
            </div>
            <MenuLink
              title='Udlæg'
              icon='MdOutlineReceiptLong'
              action={() =>
                openSidebar(
                  <Expenses
                    from={new Date(accounting!.created_date)}
                    to={new Date()}
                    unpaid={true}
                  />,
                )
              }
            />
            {accounting!.type !== AccountingType.REGULAR && (
              <MenuLink
                title='Udvikling'
                icon='MdAutoGraph'
                action={() =>
                  openSidebar(
                    <AccountingDevelopment accounting={accounting!} />,
                  )
                }
              />
            )}
          </div>
        )}
      </div>
      <ViewMode accounting={accounting!} />
      <div className='hidden sm:block'>
        <MenuButton
          sheet={accounting!}
          title='Del regnskabet'
          icon='MdShare'
          onClick={() => showSidebar(<Share />)}
        ></MenuButton>
      </div>
      <div className='hidden md:block'>
        <MenuButton
          sheet={accounting!}
          title='Kategorier'
          icon='MdCategory'
          onClick={() => showSidebar(<CategoriesForm sheet={accounting!} />)}
        />
      </div>
      <MenuButton
        sheet={accounting!}
        icon='MdSettings'
        onClick={() =>
          showSidebar(
            <Configuration sheet={accounting!}>
              <AccountConfiguration />
            </Configuration>,
          )
        }
      />
    </div>
  )
}

export default AccountingMenu

import { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { SidebarContext } from '../lib/Context'

const ResetPage = () => {
  const { pathname } = useLocation()
  const { showSidebar } = useContext(SidebarContext)

  useEffect(() => {
    showSidebar(null)
    window.scrollTo(0, 0)
    document.body.classList.remove('overflow-hidden')
  }, [pathname])

  return null
}

export default ResetPage

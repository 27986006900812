import React, { FC, useState } from 'react'
import { formatCurrency } from '../../../lib/CurrencyFormatter'
import { formatDate } from '../../../lib/Dates'
import { Sheet, Spend } from '../../../lib/Types'
import Headline from '../../Elements/Headline'
import Text from '../../Elements/Text'
import { useAccounting } from '../lib/Hooks/Accounting'
import { useExpenses, useUpdateSpend } from '../lib/Hooks/Spends'

interface Props {
  from: Date
  to: Date
  unpaid?: boolean
}

const Expenses: FC<Props> = ({ from, to, unpaid = false }) => {
  const { data: accounting } = useAccounting()
  const { data: expenses } = useExpenses(accounting!, {
    from,
    to,
    isExpense: true,
    nonPaidExpensesOnly: unpaid,
  })

  const sum = (): number => {
    return (
      expenses?.reduce((value: number, current: Spend) => {
        return value + current.amounts.at(-1)!.amount
      }, 0) || 0
    )
  }

  return (
    <>
      <Headline className='mb-4'>Udlæg</Headline>
      {expenses!.length > 0 && (
        <table className='w-full'>
          <thead>
            <tr>
              <th className='text-left'>Udlæg</th>
              <th className='text-left'>Dato</th>
              <th className='text-left'>Udlægshaver</th>
              <th className='text-left'>Beløb</th>
              <th className='text-left'>Er betalt</th>
            </tr>
          </thead>
          <tbody>
            {expenses?.map((expense: Spend) => (
              <tr key={expense.id}>
                <td>{expense.title}</td>
                <td>
                  {formatDate(new Date(expense.accounting_date), 'dd/mm/yy')}
                </td>
                <td>{expense.expense_name!}</td>
                <td>{formatCurrency(expense.amounts.at(-1)!.amount)}</td>
                <td>
                  <ExpenseItem sheet={accounting!} spend={expense} />
                </td>
              </tr>
            ))}
            <tr>
              <td className='pt-2'>
                <strong>I alt</strong>
              </td>
              <td></td>
              <td></td>
              <td>
                <strong>{formatCurrency(sum())}</strong>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      )}
      {expenses!.length === 0 && (
        <Text size='base'>Der findes ingen ubetalte udlæg.</Text>
      )}
    </>
  )
}

interface ExpenseItemProps {
  sheet: Sheet
  spend: Spend
}
const ExpenseItem: FC<ExpenseItemProps> = ({ sheet, spend }) => {
  const [isChecked, toggleChecked] = useState(spend.expense_paid)
  const { mutateAsync: updateSpend } = useUpdateSpend(sheet)

  return (
    <input
      checked={isChecked}
      type='checkbox'
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        toggleChecked(event.target.checked)
        const newSpend = structuredClone(spend)
        newSpend.expense_paid = event.target.checked
        updateSpend(newSpend)
      }}
    />
  )
}

export default Expenses

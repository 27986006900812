import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Headline from '../Elements/Headline'
import { Modal } from '../Elements/Modal'
import Text from '../Elements/Text'
import Button from '../Formik/Button'
import { useBudgets } from '../Sheets/lib/Hooks/Budget'

const TaskUpdateBudget = () => {
  const [showModal, toggleModal] = useState(false)
  const { data: budgets } = useBudgets()
  const navigate = useNavigate()

  const completeTask = async () => {
    navigate(`/budget/${budgets![0].id}`)
  }

  return (
    <>
      <Modal show={showModal} onHide={() => toggleModal(false)}>
        <Headline size='2xl' className='mb-4'>
          Jeg har allerede et budget
        </Headline>
        <Text size='base' className='mb-4'>
          Du er ved at vælge, at du allerede har et budget, der ikke er oprettet
          hos Privat Økonomi.
        </Text>
        <Text size='base' className='mb-8'>
          Det er naturligvis helt fint, da det vigtigste er, at du har et
          budget. Et budget hos Privat Økonomi giver dog afgang til rigtig mange
          brugbare funktioner, der giver en bedre indsigt i din økonomi. Du kan
          læse mere om budget hos Privat Økonomi her.
        </Text>
        <Button theme='blue' onClick={completeTask}>
          Bekræft valg
        </Button>
      </Modal>
      <div className='flex flex-col justify-between h-[calc(100%-28px)]'>
        <div className='mb-4'>
          <Headline size='3xl' className='mb-2'>
            Ajourfør Budget
          </Headline>
          <Text size='base'>
            Et budget er kun brugbart, hvis det afspejler din nuværende
            situation. Du skal i dette trin gennemgå dine posteringer, og sikre
            at det er ajourført.
          </Text>
        </div>
        <div className='flex justify-between items-center'>
          <Button theme='blue' onClick={completeTask}>
            Ajourfør Budget
          </Button>
        </div>
      </div>
    </>
  )
}

export default TaskUpdateBudget

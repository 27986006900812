import React, { useState } from 'react'
import { MdArrowDropDown, MdArrowRight } from 'react-icons/md'
import { SheetContext } from '../../../lib/Context'
import { Sheet } from '../../../lib/Types'
import Headline from '../../Elements/Headline'
import { SheetType } from '../lib/Enums/SheetType'
import { useAccounting } from '../lib/Hooks/Accounting'
import { useBudget } from '../lib/Hooks/Budget'
import Base from './Base'
import Deletion from './Deletion'

type Props = {
  sheet: Sheet
  children?: React.ReactNode
}

const useBudgetOrAccounting = (sheetType: SheetType) => {
  if (sheetType === SheetType.BUDGET) {
    return useBudget()
  } else if (sheetType === SheetType.ACCOUNTING) {
    return useAccounting()
  } else {
    throw new Error(`Invalid sheet type: ${sheetType}`)
  }
}

const Configuration = ({ sheet, children }: Props) => {
  const [expanded, setExpanded] = useState('')

  const { data } = useBudgetOrAccounting(sheet.sheet_type)

  return (
    <>
      <Headline className='mb-5'>Indstillinger</Headline>
      <SheetContext.Provider value={{ sheet: data }}>
        <Base />
        {children && (
          <>
            <Headline className='mb-2'>
              <div
                className='flex items-center cursor-pointer'
                onClick={() =>
                  setExpanded(expanded === 'custom' ? '' : 'custom')
                }
              >
                {expanded !== 'custom' && <MdArrowRight size='1.25rem' />}
                {expanded === 'custom' && <MdArrowDropDown size='1.25rem' />}
                {sheet.sheet_type === SheetType.ACCOUNTING
                  ? 'Regnskab'
                  : 'Budget'}
              </div>
            </Headline>
            {expanded === 'custom' && <div className='ml-5'>{children}</div>}
          </>
        )}
        <Deletion />
      </SheetContext.Provider>
    </>
  )
}

export default Configuration

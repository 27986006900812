import { FC, useState } from 'react'
import { Sheet } from '../../lib/Types'
import Headline from '../Elements/Headline'
import Text from '../Elements/Text'
import Button from '../Formik/Button'
import Paywall from '../Paywall'
import { SheetType } from './lib/Enums/SheetType'

interface Props {
  sheet: Sheet
  show: boolean
}

const WelcomeBudget: FC<Props> = ({ sheet, show }) => {
  const [showModal, setShowModal] = useState(show)

  return (
    <Paywall
      show={showModal}
      onHide={() => setShowModal(false)}
      sheetType={SheetType.BUDGET}
      sheetId={sheet.id}
    >
      {(closeModal: any) => (
        <>
          <Headline>Velkommen til dit nye budget</Headline>
          <Text className='my-6'>
            Se videoen herunder hvordan du hurtigt og nemt kommer i gang med dit
            nye budget. Du kan også læse mere om hvordan du gør{' '}
            <a
              className='underline'
              href='https://privat-økonomi.dk/budget'
              target='_blank'
              rel='noreferrer'
            >
              her
            </a>
            .
          </Text>
          <video controls width='100%'>
            <source
              src='https://privat-økonomi.dk/videos/opret-budget.mp4'
              type='video/mp4'
            />
          </video>
          <Text className='my-6'>
            Et budget hos Privat Økonomi er helt gratis, og du skal kun betale,
            hvis du ønsker at bruge nogle af premium funktionerne. Det koster
            49,- årligt, så har du fuld adgang til alle funktioner.
          </Text>
          <Button className='mb-5' onClick={closeModal}>
            Lad mig komme igang
          </Button>
        </>
      )}
    </Paywall>
  )
}

export default WelcomeBudget

import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query'
import * as UserAPI from '../API/UserAPI'
import { User } from '../Types'

export const useAuthenticatedUser = (): UseQueryResult<User> => {
  return useQuery({
    queryKey: ['user'],
    queryFn: async () => UserAPI.getAuthenticatedUser(),
    staleTime: Infinity,
  })
}

export const useSaveUser = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: UserAPI.saveUser,
    onMutate: (updatedUser: User) => {
      queryClient.setQueryData(['user'], updatedUser)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['budgets'] })
    },
  })
}

import { FC } from 'react'
import { MdOpenInNew } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { formatCurrency } from '../../../lib/CurrencyFormatter'
import { Accounting, Spend } from '../../../lib/Types'
import Headline from '../../Elements/Headline'
import Text from '../../Elements/Text'
import Button from '../../Formik/Button'
import { AccountingType } from '../lib/Enums/AccountingType'
import { useAccountingBalance } from '../lib/Hooks/Accounting'
import { useExpenses } from '../lib/Hooks/Spends'
import AccountingDevelopmentChart from './AccountingDevelopmentChart'

interface Props {
  accounting: Accounting
}

const summarizeItems = (spends: Spend[]) => {
  let total = 0

  const spendsToSummarize = spends.filter(
    (spend) => !spend.exclude_from_summing && !spend.expense_paid,
  )

  spendsToSummarize.forEach((spend) => {
    total += spend.amounts.at(-1)!.amount
  })

  return total
}

const getFromDate = (accounting: Accounting) => {
  const date = new Date()
  switch (accounting.type) {
    case AccountingType.WEEK: {
      const day = date.getDay()
      const diff = date.getDate() - day + (day === 0 ? -6 : 1)
      return new Date(date.getFullYear(), date.getMonth(), diff)
    }
    case AccountingType.MONTH:
      return new Date(date.getFullYear(), date.getMonth(), 1)
    case AccountingType.YEAR:
      return new Date(date.getFullYear(), 0, 1)
    default:
      return new Date(accounting.created_date)
  }
}

const getToDate = (accounting: Accounting) => {
  const date = new Date()
  switch (accounting.type) {
    case AccountingType.WEEK: {
      const dayOfWeek = date.getDay()
      return dayOfWeek === 0
        ? date
        : new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate() - dayOfWeek + 7,
          )
    }
    case AccountingType.MONTH:
      return new Date(date.getFullYear(), date.getMonth() + 1, 0)
    case AccountingType.YEAR:
      return new Date(date.getFullYear(), 11, 31)
    default:
      return date
  }
}

const Card: FC<Props> = ({ accounting }) => {
  const { data: spends } = useExpenses(accounting, {
    from: getFromDate(accounting),
    to: getToDate(accounting),
  })
  const { data: balance } = useAccountingBalance(accounting!)

  const getAccountingName = () => {
    switch (accounting.type) {
      case AccountingType.MONTH:
        return 'Månedsregnskab'
      case AccountingType.WEEK:
        return 'Ugeregnskab'
      case AccountingType.YEAR:
        return 'Årsregnskab'
      default:
        return 'Regnskab'
    }
  }

  const getAccountingSpendTitle = () => {
    switch (accounting.type) {
      case AccountingType.REGULAR:
        return 'Forbrug'
      default:
        return 'Forbrug for periode'
    }
  }

  return (
    <div className='bg-gray-100 p-4 mt-4'>
      <Link to={`/accounting/${accounting.id}`}>
        <div className='flex justify-between items-start'>
          <Headline size='base' className='mb-4'>
            <strong>{accounting.name || getAccountingName()}</strong>
          </Headline>
          <Button theme='gray'>
            <div className='flex'>
              <MdOpenInNew size='1.25rem' className='mr-2' />
              Åbn
            </div>
          </Button>
        </div>
        {accounting.dedicated_account && (
          <>
            <Text size='base'>Saldo</Text>
            <Headline size='3xl' className='mb-4'>
              kr. {balance !== null ? formatCurrency(balance!, false) : 0}
            </Headline>
          </>
        )}
        <Text size='base'>{getAccountingSpendTitle()}</Text>
        <Headline size='4xl' className='mb-4'>
          {formatCurrency(summarizeItems(spends!), false)}
        </Headline>
        {!!accounting.balance && !!accounting.budget && (
          <div className='mt-6'>
            <Text size='base'>Tilbage for periode</Text>
            <Headline size='3xl'>
              kr.{' '}
              {formatCurrency(
                accounting.budget - summarizeItems(spends!),
                false,
              )}
            </Headline>
          </div>
        )}
        {accounting.type === AccountingType.REGULAR && !!accounting.budget && (
          <div className='mt-6'>
            <Text size='base'>Budget</Text>
            <Headline size='3xl'>
              kr. {formatCurrency(accounting.budget, false)}
            </Headline>
          </div>
        )}
        {accounting.type !== AccountingType.REGULAR && (
          <div className='mt-6'>
            <AccountingDevelopmentChart
              accounting={accounting}
              showEmptyWarning={false}
            />
          </div>
        )}
      </Link>
    </div>
  )
}

export default Card

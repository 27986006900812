import { useField } from 'formik'
import React, { FC } from 'react'

interface Props {
  children: React.ReactNode
  name: string
  className?: string
  disabled?: boolean
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void
}

const Select: FC<Props> = ({
  children,
  name,
  className,
  disabled = false,
  onChange,
}) => {
  const [field, meta] = useField({ name })

  const props = {
    ...field,
    name,
    className: `w-full block px-3 py-2 text-gray-700 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 ${disabled ? 'disabled' : ''} ${className}`,
    disabled,
    ...(onChange && { onChange }),
  }

  return (
    <div>
      <select {...props}>{children}</select>
      {meta.touched && meta.error ? (
        <div className='error'>{meta.error}</div>
      ) : null}
    </div>
  )
}

export default Select

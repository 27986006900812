import Config from '../../../../lib/Config'
import { TaskType } from '../Enums/TaskType'

const getHeaders = () => ({
  Authorization: `Bearer ${localStorage.getItem('token')}`,
  'Content-Type': 'application/json',
})

export const markAsSkipped = async (task: TaskType): Promise<boolean> => {
  const response = await fetch(`${Config.API}/task/skip/`, {
    headers: getHeaders(),
    method: 'POST',
    body: JSON.stringify(task),
  })

  return response.status === 200
}

export const fetchCurrentTask = async (): Promise<TaskType> => {
  const response = await fetch(`${Config.API}/task/`, {
    headers: getHeaders(),
  })

  const taskType = await response.json()

  return taskType as TaskType
}

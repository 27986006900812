import { FC, useContext, useEffect, useState } from 'react'
import { MdArrowDropDown, MdArrowRight } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { SheetContext, SidebarContext } from '../../../lib/Context'
import { useAuthenticatedUser } from '../../../lib/Hooks/User'
import Headline from '../../Elements/Headline'
import Text from '../../Elements/Text'
import Button from '../../Formik/Button'
import { useDeleteSheet } from '../lib/Hooks/Sheet'
import { getImperativName } from './lib/Naming'

type Props = Record<string, never>

const Deletion: FC<Props> = () => {
  const { sheet } = useContext(SheetContext)
  const { showSidebar } = useContext(SidebarContext)
  const navigate = useNavigate()
  const { data: authenticatedUser } = useAuthenticatedUser()
  const [isExpanded, toggleExpanded] = useState(false)
  const { mutate: deleteSheet, isPending, isSuccess } = useDeleteSheet()

  useEffect(() => {
    if (isSuccess) {
      showSidebar(null)
      return navigate('/')
    }
  }, [isSuccess])

  return (
    <>
      {sheet.author.id === authenticatedUser?.id && (
        <>
          <Headline className='mb-2'>
            <div
              className='flex items-center cursor-pointer'
              onClick={() => toggleExpanded(!isExpanded)}
            >
              {!isExpanded && <MdArrowRight size='1.25rem' />}
              {isExpanded && <MdArrowDropDown size='1.25rem' />}
              Slet
            </div>
          </Headline>
          {isExpanded && (
            <div className='ml-5 mb-5'>
              <Text className='mb-4'>
                Slet {getImperativName(sheet.sheet_type)} ved at trykke på
                knappen herunder.
              </Text>
              <Button
                theme='red'
                className='w-full'
                onClick={() => {
                  if (
                    !window.confirm(
                      `Er du sikker på at du vil slette ${getImperativName(sheet.sheet_type)}?`,
                    )
                  ) {
                    return
                  }
                  deleteSheet(sheet)
                }}
                loading={isPending}
              >
                Slet
              </Button>
            </div>
          )}
        </>
      )}
    </>
  )
}

export default Deletion

import { Form, Formik } from 'formik'
import { useContext } from 'react'
import { SheetContext } from '../../../lib/Context'
import Button from '../../Formik/Button'
import Input from '../../Formik/Input'
import Textarea from '../../Formik/Textarea'
import { getImperativName } from '../Configuration/lib/Naming'
import { useUpdateSheet } from '../lib/Hooks/Sheet'

const Base = () => {
  const { sheet } = useContext(SheetContext)
  const { mutateAsync: saveSheet, isPending } = useUpdateSheet()

  return (
    <div className='my-5'>
      <Formik
        initialValues={{
          name: sheet.name || '',
          description: sheet.description || '',
        }}
        onSubmit={async (values, { resetForm }) => {
          const newSheet = structuredClone(sheet)
          newSheet.name = values.name
          newSheet.description = values.description

          await saveSheet(newSheet)

          resetForm({ values })
        }}
      >
        {({ submitCount: hasBeenSubmitted, dirty }) => (
          <Form>
            <p className='mb-2'>Navn</p>
            <Input
              name='name'
              placeholder={`Angiv navnet på ${getImperativName(sheet.sheet_type)}`}
              className='mb-2'
            />
            <p className='mb-2'>Beskrivelse</p>
            <Textarea
              name='description'
              placeholder={`Angiv en beskrivelse af ${getImperativName(sheet.sheet_type)}`}
              className='mb-2'
            />
            {(hasBeenSubmitted || dirty) && (
              <Button type='submit' className='w-full' loading={isPending}>
                Gem
              </Button>
            )}
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default Base

import { Form, Formik } from 'formik'
import { MdDone } from 'react-icons/md'
import * as Yup from 'yup'
import { formatCurrency } from '../../../lib/CurrencyFormatter'
import { isCurrency } from '../../../lib/Validations'
import Button from '../../Formik/Button'
import Input from '../../Formik/Input'

interface Props {
  amount: number
  isActive: boolean
  activate: () => void
  save: (amount: number) => void
}

const SpendColumn = ({ amount, isActive, activate, save }: Props) => {
  return (
    <>
      {isActive && (
        <Formik
          initialValues={{
            amount,
          }}
          validationSchema={Yup.object({
            amount: Yup.string()
              .transform((currentValue) => {
                // First remove all danish thousand seperators (.) and then replace the danish delimiter seperator (,) with the english one (.).
                // This way it's possible to save floats.
                return currentValue.replaceAll('.', '').replaceAll(',', '.')
              })
              .required('Påkrævet')
              .test(isCurrency),
          })}
          onSubmit={({ amount }) => save(amount)}
        >
          <div className='grow'>
            <Form>
              <div className='flex'>
                <Input
                  type='number'
                  name='amount'
                  placeholder='Angiv beløb'
                  size='sm'
                  className='flex-1 sm:w-20 appearance-none'
                />
                <Button size='sm' type='submit' className='ml-1'>
                  <MdDone />
                </Button>
              </div>
            </Form>
          </div>
        </Formik>
      )}
      {!isActive && (
        <div className='grow cursor-text' onClick={activate}>
          {formatCurrency(amount as number)}
        </div>
      )}
    </>
  )
}

export default SpendColumn

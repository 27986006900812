import { useContext } from 'react'
import { IconContext } from 'react-icons'
import {
  MdAutoGraph,
  MdDeleteOutline,
  MdPostAdd,
  MdSettings,
} from 'react-icons/md'
import { SidebarContext } from '../../../lib/Context'
import { Spend } from '../../../lib/Types'
import { useBudget } from '../lib/Hooks/Budget'
import { useDeleteSpend, useUpdateSpend } from '../lib/Hooks/Spends'
import SpendConfiguration from './SpendConfiguration'
import SpendDevelopment from './SpendDevelopment'

interface Props {
  spend: Spend
  isChild?: boolean
  toggleAddSpend?: () => void
  toggleChildren?: () => void
}

const TitleColumn = ({
  spend,
  isChild,
  toggleAddSpend,
  toggleChildren,
}: Props) => {
  const { data: budget } = useBudget()
  const { showSidebar, isSidebarOpen } = useContext(SidebarContext)
  const { mutate: updateSpend } = useUpdateSpend(budget!)
  const { mutate: deleteSpend } = useDeleteSpend(budget!)

  const hasChildren = () => {
    return spend.children && spend.children.length > 0
  }

  return (
    <div className='flex items-center'>
      <div
        className={`flex flex-col grow ${hasChildren() ? 'cursor-pointer' : ''}`}
        onClick={toggleChildren}
      >
        <p className={`${isChild ? 'ml-5' : ''}`}>{spend.title}</p>
        {spend.description && (
          <p className={`italic ${isChild ? 'ml-5' : ''}`}>
            {spend.description}
          </p>
        )}
      </div>

      {!hasChildren() && (
        <MdAutoGraph
          size='1.15rem'
          className='mr-2 cursor-pointer'
          title='Se udvikling for postering'
          onClick={() => {
            isSidebarOpen
              ? showSidebar(null)
              : showSidebar(<SpendDevelopment spend={spend} />)
          }}
        />
      )}
      {toggleAddSpend && !isChild && (
        <IconContext.Provider value={{ size: '1.25em', className: 'mr-1' }}>
          <MdPostAdd
            size='1.15rem'
            onClick={toggleAddSpend}
            className='mr-2 cursor-pointer'
            title='Tilføj postering'
          />
        </IconContext.Provider>
      )}
      <MdSettings
        size='1.15rem'
        className='mr-2 cursor-pointer'
        title='Rediger postering'
        onClick={() => {
          isSidebarOpen
            ? showSidebar(null)
            : showSidebar(
                <SpendConfiguration
                  saveSpend={async (spend) => {
                    updateSpend(spend)
                    showSidebar(null)
                  }}
                  spend={spend}
                />,
              )
        }}
      />
      <IconContext.Provider value={{ size: '1.25em' }}>
        <MdDeleteOutline
          size='1.15rem'
          onClick={async () => {
            if (window.confirm('Dette vil slette posteringen. Er du sikker?')) {
              deleteSpend(spend)
            }
          }}
          className='cursor-pointer'
          title='Slet postering'
        />
      </IconContext.Provider>
    </div>
  )
}

export default TitleColumn

import { FC, useState } from 'react'
import { MdPostAdd } from 'react-icons/md'
import Button from '../../Formik/Button'
import SpendForm from './SpendForm'

type Props = {
  accountingDate?: Date
}

const AddSpend: FC<Props> = ({ accountingDate }) => {
  const [showForm, toggleForm] = useState(false)

  return (
    <>
      <SpendForm
        show={showForm}
        hideModal={() => toggleForm(false)}
        accountingDate={accountingDate}
      />

      <Button onClick={() => toggleForm(true)} theme='blue' size='sm'>
        <div className='flex items-center'>
          <MdPostAdd className='mr-2' />
          Ny postering
        </div>
      </Button>
    </>
  )
}

export default AddSpend

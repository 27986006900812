import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { Accounting, Budget, Sheet, SheetCategory } from '../../../../lib/Types'
import * as API from '../API/AccountingAPI'

export const useCreateAccounting = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (accounting: Partial<Accounting>) =>
      API.createAccounting(accounting),
    onSuccess: (newSheet: Sheet) => {
      queryClient.setQueryData(['accountings'], (oldData: Sheet[]) => {
        return oldData ? [...oldData, newSheet] : [newSheet]
      })
    },
  })
}

export const useCreateReconciliationAccounting = (budget: Budget) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (accounting: Partial<Accounting>) =>
      API.createReconciliationAccounting(accounting),
    onSuccess: (newSheet: Accounting) => {
      queryClient.setQueryData(['accountings'], (oldData: Sheet[]) => {
        return oldData ? [...oldData, newSheet] : [newSheet]
      })

      queryClient.invalidateQueries({
        queryKey: ['budgets', budget.id],
      })
    },
  })
}

export const useAccountings = (): UseQueryResult<Accounting[]> => {
  return useQuery({
    queryKey: ['accountings'],
    queryFn: () => API.getAccountings(),
    staleTime: Infinity,
  })
}

export const useAccounting = (): UseQueryResult<Accounting> => {
  const params = useParams()

  if (!params.accountingId) {
    throw Error('Accounting ID is missing from params')
  }

  const accountingId = parseInt(params.accountingId, 10)

  return useQuery({
    queryKey: ['accountings', accountingId],
    queryFn: async () => API.getAccounting(accountingId),
    staleTime: Infinity,
  })
}

export const useAccountingBalance = (
  accounting: Accounting,
): UseQueryResult<number> => {
  return useQuery({
    queryKey: ['accountings', accounting.id, 'balance'],
    queryFn: async () => API.getAccountingBalance(accounting.id),
    staleTime: Infinity,
  })
}

export const useAccountingBalances = (
  accounting: Accounting,
): UseQueryResult<Record<string, number>> => {
  return useQuery({
    queryKey: ['accountings', accounting.id, 'balances'],
    queryFn: async () => API.getAccountingBalances(accounting.id),
    staleTime: Infinity,
  })
}

export const useAccountingDevelopmentBalances = (
  accounting: Accounting,
  category: SheetCategory | null,
): UseQueryResult<Record<string, number>> => {
  const queryKey = [
    'accountings',
    accounting.id,
    'balances',
    'development',
    category ? category.id : null,
  ]

  return useQuery({
    queryKey,
    queryFn: async () =>
      API.getAccountingDevelopmentBalances(
        accounting.id,
        category ? category.id : 0,
      ),
    staleTime: Infinity,
  })
}

import Headline from '../Elements/Headline'
import Text from '../Elements/Text'
import Category from './Category'
import { TaskType } from './lib/Enums/TaskType'
import { useTask } from './lib/Hooks/Tasks'
import TaskBudgetCreate from './TaskCreateBudget'
import TaskUpdateBudget from './TaskUpdateBudget'

const Guide = () => {
  const { data: task } = useTask()

  const categories = [
    {
      category: 'budget',
      number: '01',
      name: 'Budget',
      isCompleted: false,
      isActive: true,
      backgroundColor: 'white',
    },
    {
      category: 'accounting',
      number: '02',
      name: 'Regnskab',
      isCompleted: false,
      isActive: false,
      backgroundColor: 'bg-zinc-100',
    },
    {
      category: 'habit',
      number: '03',
      name: 'Vaner',
      isCompleted: false,
      isActive: false,
      backgroundColor: 'bg-zinc-50',
    },
  ]

  const getCompletionLevel = (
    category: string,
  ): 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 => {
    if (!task) return 0

    if (category === 'budget') {
      switch (task) {
        case TaskType.BUDGET_CREATE:
          return 1
        case TaskType.BUDGET_UPDATE:
          return 2
        default:
          return 0
      }
    }

    return 0
  }

  return (
    <>
      <Headline className='mb-2 flex'>Få styr på din økonomi</Headline>
      <Text size='base' className='flex items-end mb-5'>
        Vi kan hjælpe dig godt på vej til at få styr på din økonomi.
        <br />
        Brug vores guide herunder, og få overblik og indsigt i din økonomi, et
        skridt ad gangen.
        <a
          href='https://privat-økonomi.dk/artikel/faa-styr-paa-din-okonomi'
          target='_blank'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 512 512'
            width='16'
            className='ml-2 cursor-pointer pb-1'
          >
            <path d='M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM169.8 165.3c7.9-22.3 29.1-37.3 52.8-37.3l58.3 0c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24l0-13.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1l-58.3 0c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z' />
          </svg>
        </a>
      </Text>
      <div className='flex flex-col md:flex-row mb-5 overflow-hidden'>
        {categories.map((category, index) => (
          <Category
            key={index}
            completionLevel={getCompletionLevel(category.category)}
            backgroundColor={category.backgroundColor}
          >
            <>
              {category.isActive && (
                <>
                  <Headline className='mb-2' size='base'>
                    <strong>
                      {category.number} {category.name}
                    </strong>
                  </Headline>
                  {task === TaskType.BUDGET_CREATE && <TaskBudgetCreate />}
                  {task !== TaskType.BUDGET_CREATE && <TaskUpdateBudget />}
                </>
              )}
              {!category.isActive && (
                <div className='flex flex-col relative h-full items-center justify-center'>
                  <Headline size='4xl'>
                    {!category.isCompleted
                      ? category.number
                      : `${category.number} ${category.name}`}
                  </Headline>
                </div>
              )}
            </>
          </Category>
        ))}
      </div>
    </>
  )
}

export default Guide

import { FC, useEffect, useState } from 'react'
import { IoSparkles } from 'react-icons/io5'
import { useParams } from 'react-router-dom'
import { useAuthenticatedUser } from '../../lib/Hooks/User'
import { User } from '../../lib/Types'
import Paywall from '../Paywall'
import { SheetType } from '../Sheets/lib/Enums/SheetType'
import AIPaywall from './AIPaywall'
import Chat from './Chat'

type Props = {
  user: User | null 
}

const Toggle: FC<Props> = ({ user }) => {
  const [showChat, setShowChat] = useState(false)
  const params = useParams()
  const budgetId = params.budgetId || undefined

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'i') {
        setShowChat(!showChat)
        event.preventDefault()
      }
      if (event.key === 'Escape') {
        setShowChat(false)
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [showChat])

  return (
    <>
      <div
        onClick={() => setShowChat(!showChat)}
        className='inline-block align-bottom mr-2 cursor-pointer'
        title='Tryk på CTRL + E eller CMD + E for at åbne assistenten.'
      >
        <IoSparkles size='1.35rem' color='#FFC300' />
      </div>
      {showChat && user!.has_paid_sheet && (
        <Chat close={() => setShowChat(false)} />
      )}
      {showChat && !user!.has_paid_sheet && (
        <Paywall
          show={showChat && !user!.has_paid_sheet}
          onHide={() => setShowChat(false)}
          sheetType={SheetType.BUDGET}
          sheetId={budgetId ? parseInt(budgetId, 10) : undefined}
        >
          <AIPaywall />
        </Paywall>
      )}
    </>
  )
}

export default Toggle

import { FC } from 'react'
import {
  MdAccountBalanceWallet,
  MdAutoGraph,
  MdCategory,
  MdInsertChart,
  MdLogout,
  MdOutlineReceiptLong,
  MdShare,
} from 'react-icons/md'

type Props = {
  title: string
  icon: string
  action: () => void
}

const MenuLink: FC<Props> = ({ title, icon, action }) => {
  return (
    <a
      className='flex hover:underline hover:cursor-pointer mb-4'
      onClick={action}
    >
      {icon === 'MdAccountBalanceWallet' && (
        <MdAccountBalanceWallet size='1.5rem' />
      )}
      {icon === 'MdAutoGraph' && <MdAutoGraph size='1.5rem' />}
      {icon === 'MdInsertChart' && <MdInsertChart size='1.5rem' />}
      {icon === 'MdLogout' && <MdLogout size='1.5rem' />}
      {icon === 'MdCategory' && <MdCategory size='1.5rem' />}
      {icon === 'MdShare' && <MdShare size='1.5rem' />}
      {icon === 'MdOutlineReceiptLong' && (
        <MdOutlineReceiptLong size='1.5rem' />
      )}
      <span className='ml-2'>{title}</span>
    </a>
  )
}

export default MenuLink

import { FC, Suspense, useState } from 'react'
import { MdArrowDropDown, MdArrowRight } from 'react-icons/md'
import LoadingSpinner from '../../Elements/LoadingSpinner'
import Category from './Category'

type Props = {
  from: Date
  to: Date
}

const Categories: FC<Props> = ({ from, to }) => {
  const [isExpanded, toggleExpanded] = useState(false)

  return (
    <table className={'min-w-full table-accounting'}>
      <tbody>
        <tr className='bg-gray-100 border-b border-x border-gray-300'>
          <th
            scope='col'
            className='text-sm font-medium text-gray-900 px-6 py-4 text-left'
          >
            <div
              className='flex cursor-pointer'
              onClick={() => toggleExpanded(!isExpanded)}
            >
              <div>
                {isExpanded && <MdArrowDropDown size='1.25rem' />}
                {!isExpanded && <MdArrowRight size='1.25rem' />}
              </div>
              <p>Kategorier</p>
            </div>
          </th>
        </tr>
        {isExpanded && (
          <Suspense fallback={<LoadingSpinner />}>
            <Category from={from} to={to} />
          </Suspense>
        )}
      </tbody>
    </table>
  )
}

export default Categories

import { Form, Formik } from 'formik'
import { FC } from 'react'
import * as Yup from 'yup'
import { getDateFromYearAndMonth } from '../../../lib/Dates'
import { SpendCreate } from '../../../lib/Types'
import { isCurrency } from '../../../lib/Validations'
import Headline from '../../Elements/Headline'
import { Modal } from '../../Elements/Modal'
import Button from '../../Formik/Button'
import Input from '../../Formik/Input'
import Select from '../../Formik/Select'
import Textarea from '../../Formik/Textarea'
import { DistributionType } from '../lib/Enums/DistributionType'
import { SpendFrequency } from '../lib/Enums/SpendFrequency'
import { SpendType } from '../lib/Enums/SpendType'
import { useBudget } from '../lib/Hooks/Budget'
import { useAddSpend } from '../lib/Hooks/Spends'

interface Props {
  toggleHide: () => void
  show: boolean
  saved?: () => void
}

const AddIncome: FC<Props> = ({ toggleHide, show, saved }) => {
  const { data: budget } = useBudget()
  const { mutate: addSpend } = useAddSpend(budget!)

  const save = async (spend: SpendCreate) => {
    addSpend(spend)

    toggleHide()

    if (saved) saved()
  }

  return (
    <Modal show={show} onHide={toggleHide}>
      {(closeModal: any) => (
        <>
          <Headline className='mb-4'>Tilføj indtægt</Headline>
          <Formik
            initialValues={{
              title: '',
              description: '',
              frequency: SpendFrequency.MONTHLY,
              amount: 0,
              payment_month: '1',
            }}
            validationSchema={Yup.object({
              title: Yup.string().required('Påkrævet'),
              frequency: Yup.string().required('Påkrævet'),
              amount: Yup.string()
                .transform((currentValue) => {
                  // First remove all danish thousand seperators (.) and then replace the danish delimiter seperator (,) with the english one (.).
                  // This way it's possible to save floats.
                  return currentValue.replaceAll('.', '').replaceAll(',', '.')
                })
                .required('Påkrævet')
                .test(isCurrency),
              payment_month: Yup.string().required('Påkrævet'),
            })}
            onSubmit={(values) => {
              const { frequency, amount, ...rest } = values

              const newSpend = {
                ...rest,
                type: SpendType.INCOME,
                accounting_date: getDateFromYearAndMonth(
                  new Date().getFullYear(),
                  parseInt(values.payment_month, 10),
                ),
                distribution_type: DistributionType.CURRENCY,
                distribution_primary_user: amount,
                amounts: [
                  {
                    formula: amount.toString(),
                    frequency,
                  },
                ],
              }
              save(newSpend)
              closeModal()
            }}
          >
            <AddSpendForm />
          </Formik>
        </>
      )}
    </Modal>
  )
}

const AddSpendForm = () => {
  return (
    <Form>
      <>
        <p className='mb-2'>Navn på indtægt</p>
        <Input
          name='title'
          placeholder='Navnet på din indtægt'
          className='mb-4'
        />
        <p className='mb-2'>Note</p>
        <Textarea
          name='description'
          placeholder='Angiv en note til din indtægt'
          className='mb-4'
        />
      </>
      <p className='mb-2'>Vælg indkomstfrekvens</p>
      <Select name='frequency' className='mb-4'>
        <option value={SpendFrequency.MONTHLY}>Månedlig</option>
        <option value={SpendFrequency.BIMONTHLY}>Hver 2. måned</option>
        <option value={SpendFrequency.QUARTERLY}>Kvartalvis</option>
        <option value={SpendFrequency.BIYEARLY}>Halvårlig</option>
        <option value={SpendFrequency.YEARLY}>Årlig</option>
      </Select>
      <p className='mb-2'>Indtast indtægt</p>
      <Input
        type='number'
        name='amount'
        placeholder='Angiv beløb'
        className='mb-4'
      />
      <Button type='submit'>Tilføj</Button>
    </Form>
  )
}

export default AddIncome

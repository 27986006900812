import { useRef } from 'react'
import { incomeForUser } from '../../../lib/Calculator'
import { formatCurrency } from '../../../lib/CurrencyFormatter'
import { UserType } from '../../../lib/Enums/UserType'
import { useBudget } from '../lib/Hooks/Budget'
import { useIncomes } from '../lib/Hooks/Spends'
import './Budget.css'
import IncomeRow from './IncomeRow'

const BudgetIncome = () => {
  const { data: budget } = useBudget()
  const { data: spends } = useIncomes(budget!)

  const refSticky = useRef<HTMLTableSectionElement>(null)

  return (
    <>
      <table
        className={`table-budget min-w-full ${budget!.user ? 'col-5' : ''}`}
      >
        <thead ref={refSticky} className={'bg-gray-300 border border-gray-300'}>
          <tr>
            <th
              scope='col'
              className='text-sm font-medium text-gray-900 px-6 py-4 text-left'
            >
              Titel
            </th>
            <th
              scope='col'
              className='text-sm font-medium text-gray-900 px-6 py-4 text-left'
            >
              {!budget!.user && <span>Måned</span>}
              {budget!.user && (
                <span>
                  {budget!.author.name || budget!.author.email.split('@')[0]}
                </span>
              )}
            </th>
            {budget!.user && (
              <th
                scope='col'
                className='text-sm font-medium text-gray-900 px-6 py-4 text-left'
              >
                {budget!.user.name || budget!.user.email.split('@')[0]}
              </th>
            )}
          </tr>
        </thead>
      </table>
      {spends!
        .sort((a, b) => (a.title > b.title ? 1 : -1))
        .map((spend) => (
          <IncomeRow key={spend.id} spend={spend} />
        ))}
      <table
        className={`min-w-full table-budget ${budget!.user ? 'col-5' : ''}`}
      >
        <tbody>
          <tr className='bg-gray-300 border-b border-x border-gray-300'>
            <th className='text-sm font-medium text-gray-900 px-6 py-4 text-left'>
              I alt
            </th>
            <th className='text-sm font-medium text-gray-900 px-6 py-4 text-left'>
              {formatCurrency(incomeForUser(spends!, UserType.PRIMARY))}
            </th>
            {budget!.user && (
              <th className='text-sm font-medium text-gray-900 px-6 py-4 text-left'>
                {formatCurrency(incomeForUser(spends!, UserType.SECONDARY))}
              </th>
            )}
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default BudgetIncome

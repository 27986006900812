import Headline from '../Elements/Headline'
import Text from '../Elements/Text'

const AIPaywall = () => {
  return (
    <>
      <Headline>Få adgang til AI assistenten</Headline>
      <Text className='my-6'>
        For at få adgang til vores AI assistent, skal du købe et abonnement på
        et budget eller et regnskab.
      </Text>
      <img src='https://privat-økonomi.dk/images/ai-assistent.png' />
      <Text className='my-6'>
        AI assistenten kan hjælpe dig med at analysere dit budget eller dit
        regnskab samt give dig gode råd og indsigter. Den kan også hjælpe med
        konkrete opgaver, såsom at tilføje nye posteringer til dit budget.
      </Text>
    </>
  )
}

export default AIPaywall

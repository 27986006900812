import { FC, useState } from 'react'
import Config from '../../lib/Config'
import { Sheet } from '../../lib/Types'
import Headline from '../Elements/Headline'
import Text from '../Elements/Text'
import Button from '../Formik/Button'
import Paywall from '../Paywall'
import { SheetType } from './lib/Enums/SheetType'

interface Props {
  sheet: Sheet
  show: boolean
}

const WelcomeAccounting: FC<Props> = ({ sheet, show }) => {
  const [showModal, setShowModal] = useState(show)

  return (
    <Paywall
      show={showModal}
      onHide={() => setShowModal(false)}
      sheetType={SheetType.ACCOUNTING}
      sheetId={sheet.id}
    >
      {(closeModal: any) => (
        <>
          <Headline>Velkommen til dit nye regnskab </Headline>
          <Text className='mt-3'>
            Det er nemt at komme i gang med at bogføre. Du kan oprette en ny
            postering ved at klikke på knappen "Ny postering" i højre hjørne.
          </Text>
          <Text className='mt-6'>
            Du har 14 dages gratis adgang til regnskabet, hvorefter du skal
            betale
            {Config.PRICE_ACCOUNTING},- kr årligt for at fortsætte. Hvis du har
            spørgsmål, er du altid velkommen til at kontakte os på
            kontakt@privat-økonomi.dk.
          </Text>
          <Text className='mt-3 mb-6'>
            Du kan læse mere om regnskabet og funktionerne{' '}
            <a
              className='underline'
              href='https://privat-økonomi.dk/regnskab/hvad-er-et-regnskab/'
              target='_blank'
              rel='noreferrer'
            >
              her
            </a>
            .
          </Text>
          <Button className='mb-5' onClick={closeModal}>
            Lad mig komme igang
          </Button>
        </>
      )}
    </Paywall>
  )
}

export default WelcomeAccounting

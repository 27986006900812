import { FC, ReactNode, useState } from 'react'
import { MdCategory, MdDownload, MdIosShare, MdSettings } from 'react-icons/md'
import { Sheet } from '../../lib/Types'
import Button from '../Formik/Button'
import Paywall from '../Paywall'

interface Props {
  sheet: Sheet
  title?: string
  icon: string
  onClick: () => any
  children?: ReactNode
}

const MenuButton: FC<Props> = ({ sheet, title, icon, onClick, children }) => {
  const [showPaywall, togglePaywall] = useState(false)

  const action = () => {
    if (children && !sheet.is_paid) {
      togglePaywall(true)
    } else {
      onClick()
    }
  }

  return (
    <>
      <Button
        theme='gray'
        tooltip='Balanceoverblik'
        className='ml-2'
        onClick={action}
      >
        <div className='flex'>
          {icon === 'MdShare' && <MdIosShare size='1.25rem' />}
          {icon === 'MdCategory' && <MdCategory size='1.25rem' />}
          {icon === 'MdSettings' && <MdSettings size='1.25rem' />}
          {icon === 'MdDownload' && <MdDownload size='1.25rem' />}
          {title && <span className='ml-2'>{title}</span>}
        </div>
      </Button>
      <Paywall
        show={showPaywall}
        onHide={() => togglePaywall(false)}
        sheetType={sheet.sheet_type}
        sheetId={sheet.id}
      >
        {children}
      </Paywall>
    </>
  )
}

export default MenuButton

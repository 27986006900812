import { FC } from 'react'
import { Spend } from '../../../lib/Types'
import { DistributionType } from '../lib/Enums/DistributionType'
import { useBudget } from '../lib/Hooks/Budget'
import SpendUserColumns from './SpendUserColumns'
import TitleColumn from './TitleColumn'

interface Props {
  spend: Spend
}

const IncomeRow: FC<Props> = ({ spend }) => {
  const { data: budget } = useBudget()

  return (
    <>
      <table
        className={`min-w-full table-budget ${budget!.user ? 'col-5' : ''}`}
      >
        <tbody>
          <tr className='bg-white border-b border-gray-300'>
            <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
              <TitleColumn spend={spend} />
            </td>
            <SpendUserColumns
              spend={spend}
              types={[DistributionType.CURRENCY]}
            />
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default IncomeRow

import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import Headline from '../../Elements/Headline'
import Text from '../../Elements/Text'
import Button from '../../Formik/Button'
import Input from '../../Formik/Input'
import { useInviteUser, useSheet, useUpdateSheet } from '../lib/Hooks/Sheet'
import { getImperativName, getSingularName } from './lib/Naming'

const Share = () => {
  const { data: sheet } = useSheet()
  const { mutate: updateSheet, isPending: isSaving } = useUpdateSheet()
  const { mutate: inviteUserToSheet, isPending: isInviting } = useInviteUser()

  const uninviteUser = async () => {
    if (
      !window.confirm(
        `Dette vil slette invitation for ${sheet!.invites}. Er du sikker?`,
      )
    ) {
      return
    }

    const newSheet = structuredClone(sheet)
    newSheet!.invites = undefined

    updateSheet(newSheet!)
  }

  const inviteUser = async (email: string) => {
    if (
      !window.confirm(
        `Er du sikker på at du vil dele ${getImperativName(sheet!.sheet_type)} med ${email}?`,
      )
    ) {
      return
    }

    const newSheet = structuredClone(sheet)
    newSheet!.invites = email

    inviteUserToSheet(newSheet!)
  }

  const removeUser = async () => {
    if (
      !window.confirm(
        `Er du sikker på at du vil fjerne deling fra ${sheet!.user?.name || sheet!.user?.email}?`,
      )
    ) {
      return
    }

    const newSheet = structuredClone(sheet)
    newSheet!.user = undefined

    updateSheet(newSheet!)
  }

  return (
    <>
      <Headline className='mb-5'>
        Del {getImperativName(sheet!.sheet_type)}
      </Headline>
      {sheet!.user && (
        <>
          <Text className='mb-4'>
            Du har delt {getImperativName(sheet!.sheet_type)} med{' '}
            <strong>{sheet!.user.name || sheet!.user.email}</strong>. Stop
            delingen ved at trykke på knappen herunder.
          </Text>
          <Button
            theme='red'
            className='w-full'
            onClick={removeUser}
            loading={isSaving}
          >
            Fjern deling
          </Button>
        </>
      )}
      {!sheet!.user && (
        <>
          {sheet!.invites && (
            <Text className='mt-4'>
              Du har inviteret <strong>{sheet!.invites}</strong> til{' '}
              {getImperativName(sheet!.sheet_type)}.<br />
              Tryk{' '}
              <u className='cursor-pointer' onClick={uninviteUser}>
                her
              </u>{' '}
              for at annullere invitationen.
            </Text>
          )}
          {!sheet!.invites && (
            <Formik
              initialValues={{
                email: '',
              }}
              validationSchema={Yup.object({
                email: Yup.string()
                  .email('Ugyldig e-mail')
                  .required('Påkrævet'),
              })}
              onSubmit={(values) => inviteUser(values.email)}
            >
              <Form>
                <Text className='mb-2'>
                  Her har du mulighed for at dele{' '}
                  {getImperativName(sheet!.sheet_type)} med en anden person.
                  Personen du deler {getImperativName(sheet!.sheet_type)} med,
                  vil få samme adgang til det som dig, og har mulighed for at
                  tilføje, fjerne og redigere alle posteringer.
                </Text>
                <Text className='mb-2'>
                  Det kan f.eks. være en god ide at dele{' '}
                  {getSingularName(sheet!.sheet_type)} med en partner.
                </Text>
                <Text>
                  Angiv den person du ønsker at dele{' '}
                  {getImperativName(sheet!.sheet_type)} med, så sender vi en
                  invitation til personen.
                </Text>
                <div className='flex mt-4'>
                  <Input
                    placeholder='Indtast e-mail adresse'
                    type='email'
                    name='email'
                    className='flex-1'
                  />
                  <Button type='submit' loading={isInviting} className='ml-2'>
                    Inviter
                  </Button>
                </div>
              </Form>
            </Formik>
          )}
        </>
      )}
    </>
  )
}

export default Share

import { useRef } from 'react'
import { sum, sumForUser } from '../../../lib/Calculator'
import { formatCurrency } from '../../../lib/CurrencyFormatter'
import { UserType } from '../../../lib/Enums/UserType'
import { SheetCategory, Spend } from '../../../lib/Types'
import { SpendFrequency } from '../lib/Enums/SpendFrequency'
import { useBudget } from '../lib/Hooks/Budget'
import { useCategories } from '../lib/Hooks/Categories'
import { useExpenses } from '../lib/Hooks/Spends'
import './Budget.css'
import Spends from './Spends'

const BudgetExpenses = () => {
  const { data: budget } = useBudget()
  const { data: categories } = useCategories(budget!)
  const { data: spends } = useExpenses(budget!)

  const refSticky = useRef<HTMLTableSectionElement>(null)

  /**
   * Split the spends into categories, by moving spends with a parent into the parent spends children array.
   */
  const categorizeSpends = (category: SheetCategory): Spend[] => {
    const categorizedSpends: { [k: number]: Spend } = {}

    const spendsInCategory = spends!.filter((item) => {
      return item.category.id === category.id
    })

    // Start by building the list of parent spends
    spendsInCategory.forEach((item) => {
      if (!item.parent_id) {
        categorizedSpends[item.id] = item
        categorizedSpends[item.id].children = []
      }
    })

    // Add all children to the their parents.
    spendsInCategory.forEach((item) => {
      if (item.parent_id && categorizedSpends[item.parent_id]) {
        categorizedSpends[item.parent_id].children.push(item)
      }
    })

    // Make sure the spends in the new array are ordered by their item.order property
    const orderedSpendsInCategory = Object.values(categorizedSpends).sort(
      (a, b) => a.order - b.order,
    )

    // Order the children of the parent spends
    orderedSpendsInCategory.forEach((item) => {
      item.children.sort((a, b) => a.order - b.order)
    })

    return orderedSpendsInCategory
  }

  return (
    <>
      <table
        className={`table-budget min-w-full ${budget!.user ? 'col-5' : ''}`}
      >
        <thead ref={refSticky} className={'bg-gray-300 border border-gray-300'}>
          <tr>
            <th
              scope='col'
              className='text-sm font-medium text-gray-900 px-6 py-4 text-left'
            >
              Titel
            </th>
            <th
              scope='col'
              className='text-sm font-medium text-gray-900 px-6 py-4 text-left'
            >
              År
            </th>
            <th
              scope='col'
              className='text-sm font-medium text-gray-900 px-6 py-4 text-left'
            >
              Måned
            </th>
            {budget!.user && (
              <>
                <th
                  scope='col'
                  className='text-sm font-medium text-gray-900 px-6 py-4 text-left'
                >
                  {budget!.author.name || budget!.author.email.split('@')[0]}
                </th>

                <th
                  scope='col'
                  className='text-sm font-medium text-gray-900 px-6 py-4 text-left'
                >
                  {budget!.user.name || budget!.user.email.split('@')[0]}
                </th>
              </>
            )}
          </tr>
        </thead>
      </table>
      {categories!.map((category) => (
        <Spends
          key={category.id}
          category={category}
          spends={categorizeSpends(category)}
        />
      ))}
      <table
        className={`min-w-full table-budget ${budget!.user ? 'col-5' : ''}`}
      >
        <tbody>
          <tr className='bg-gray-300 border-b border-x border-gray-300'>
            <th className='text-sm font-medium text-gray-900 px-6 py-4 text-left'>
              I alt
            </th>
            <th className='text-sm font-medium text-gray-900 px-6 py-4 text-left'>
              {formatCurrency(sum(spends!, SpendFrequency.YEARLY))}
            </th>
            <th className='text-sm font-medium text-gray-900 px-6 py-4 text-left'>
              {formatCurrency(sum(spends!, SpendFrequency.MONTHLY))}
            </th>
            {budget!.user && (
              <>
                <th className='text-sm font-medium text-gray-900 px-6 py-4 text-left'>
                  {formatCurrency(sumForUser(spends!, UserType.PRIMARY))}
                </th>
                <th className='text-sm font-medium text-gray-900 px-6 py-4 text-left'>
                  {formatCurrency(sumForUser(spends!, UserType.SECONDARY))}
                </th>
              </>
            )}
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default BudgetExpenses

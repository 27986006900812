import { FC, useState } from 'react'
import {
  MdMoneyOff,
  MdOutlineCalculate,
  MdOutlineReceiptLong,
} from 'react-icons/md'
import { formatCurrency } from '../../../lib/CurrencyFormatter'
import { formatDate } from '../../../lib/Dates'
import { Spend } from '../../../lib/Types'
import { useAccounting } from '../lib/Hooks/Accounting'
import { useExpenses } from '../lib/Hooks/Spends'
import './Accounting.css'
import SpendForm from './SpendForm'

type Props = {
  from: Date
  to: Date
  showHeader?: boolean
  showCategory?: boolean
}

const ViewSpends: FC<Props> = ({
  from,
  to,
  showHeader = true,
  showCategory = true,
}) => {
  const [spendToEdit, setSpendToEdit] = useState<Spend | null>(null)

  const { data: accounting } = useAccounting()
  const { data: spends } = useExpenses(accounting!, {
    from,
    to,
  })

  return (
    <>
      <table className={'min-w-full table-accounting'}>
        <tbody>
          {showHeader && (
            <tr className='bg-gray-100 border-b border-x border-gray-300'>
              <th
                scope='col'
                className='text-sm font-medium text-gray-900 px-6 py-4 text-left'
              >
                Titel
              </th>
              <th
                scope='col'
                className='text-sm font-medium text-gray-900 px-6 py-4 text-left'
              >
                Dato
              </th>
              {showCategory && (
                <th
                  scope='col'
                  className='text-sm font-medium text-gray-900 px-6 py-4 text-left'
                >
                  Kategori
                </th>
              )}
              <th
                scope='col'
                className='text-sm font-medium text-gray-900 px-6 py-4 text-left'
              >
                Beløb
              </th>
            </tr>
          )}
          <SpendForm
            spend={spendToEdit || undefined}
            show={spendToEdit !== null}
            hideModal={() => setSpendToEdit(null)}
          />
          {spends!.length === 0 && (
            <tr className='bg-white border-x border-b border-gray-300'>
              <td
                className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'
                colSpan={4}
              >
                Der er ikke oprettet nogle posteringer i regnskabet.
              </td>
            </tr>
          )}
          {spends!.map((spend) => (
            <tr
              key={spend.id}
              className='bg-white border-x border-b border-gray-300 cursor-pointer'
              onClick={() => setSpendToEdit(spend)}
            >
              <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
                <div className='flex items-center justify-between'>
                  {spend.title}
                  {spend.is_expense && (
                    <div className='flex '>
                      <MdOutlineReceiptLong />
                      <span
                        className={`text-xs ${spend.expense_paid ? '' : 'text-theme-red'} ml-1`}
                      >
                        Udlæg for {spend.expense_name}
                      </span>
                    </div>
                  )}
                </div>
              </td>
              <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
                {formatDate(new Date(spend.accounting_date))}
              </td>
              {showCategory && (
                <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
                  {spend.category.name}
                </td>
              )}
              <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
                <div className='flex items-center'>
                  {formatCurrency(spend.amounts.at(-1)!.amount)}
                  <div className='flex mr-2'>
                    {!spend.exclude_from_summing && (
                      <MdOutlineCalculate className='ml-2' />
                    )}
                    {!!spend.is_expense && <MdMoneyOff className='ml-2' />}
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

export default ViewSpends

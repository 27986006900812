import { FC, useEffect, useState } from 'react'
import { formatDate, getDateFromKey } from '../../../lib/Dates'
import { Accounting, SheetCategory } from '../../../lib/Types'
import LineChart from '../../Charts/LineChart'
import Text from '../../Elements/Text'
import { AccountingType } from '../lib/Enums/AccountingType'
import { useAccountingDevelopmentBalances } from '../lib/Hooks/Accounting'
import { useCategories } from '../lib/Hooks/Categories'

interface Props {
  accounting: Accounting
  showEmptyWarning?: boolean
  showCategories?: boolean
}

const AccountingDevelopmentChart: FC<Props> = ({
  accounting,
  showEmptyWarning = true,
  showCategories = false,
}) => {
  const [selectedCategory, setSelectedCategory] =
    useState<SheetCategory | null>(null)
  const { data: categories } = useCategories(accounting!)
  const { data: amounts } = useAccountingDevelopmentBalances(
    accounting,
    selectedCategory,
  )

  const [dataToRender, setDataToRender] = useState<{ [key: string]: number }>(
    {},
  )

  useEffect(() => {
    const data = [] as { key: number; value: number }[]
    const dataToSet = {} as { [key: string]: number }

    Object.keys(amounts!).forEach((key) => {
      data.push({ key: parseInt(key, 10), value: amounts![key] })
    })

    // Sort data by key descending.
    data.sort((a, b) => {
      const groupA = a.key.toString().match(/^(\d{4})(.*)/)!
      const groupB = b.key.toString().match(/^(\d{4})(.*)/)!

      const yearA = parseInt(groupA[1], 10)
      const kickerA = parseInt(groupA[2], 10)

      const yearB = parseInt(groupB[1], 10)
      const kickerB = parseInt(groupB[2], 10)

      if (yearA !== yearB) {
        return yearA - yearB
      } else {
        return kickerA - kickerB
      }
    })

    data.forEach((item) => {
      const date = getDateFromKey(accounting, item.key)

      let dateKey: string

      switch (accounting.type) {
        case AccountingType.WEEK:
          dateKey = `uge ${formatDate(date, 'ww / yy')}`
          break
        case AccountingType.MONTH:
          dateKey = `${formatDate(date, 'mmm yy')}`
          break
        case AccountingType.YEAR:
          dateKey = `${formatDate(date, 'yyyy')}`
          break
      }

      dataToSet[dateKey!] = item.value
    })

    setDataToRender(dataToSet)
  }, [amounts])

  if (!amounts) {
    return null
  }

  return (
    <>
      {Object.keys(amounts).length === 0 && showEmptyWarning && (
        <p className='italic'>Der er ikke nok data til at vise grafen</p>
      )}
      {Object.keys(amounts).length > 0 && (
        <>
          {showCategories && (
            <>
              <Text>Kategori</Text>
              <select
                className='mt-2 mb-5 w-full block px-3 py-2 text-gray-700 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500'
                onChange={(event) => {
                  const foundCategory = categories?.find(
                    (category) =>
                      category.id === parseInt(event.target.value, 10),
                  )
                  setSelectedCategory(foundCategory!)
                }}
              >
                <option value={0}>Alle</option>
                {categories!.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </>
          )}

          <LineChart
            data={dataToRender}
            budget={
              selectedCategory ? selectedCategory.budget : accounting.budget
            }
          />
        </>
      )}
    </>
  )
}

export default AccountingDevelopmentChart

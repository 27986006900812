import { FC, ReactNode, useContext, useRef, useState } from 'react'
import { MdMoreVert, MdPostAdd } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import useOnClickOutside from 'use-onclickoutside'
import { BudgetContext, SidebarContext } from '../../../lib/Context'
import Button from '../../Formik/Button'
import CategoriesForm from '../Configuration/CategoriesForm'
import Configuration from '../Configuration/Configuration'
import Share from '../Configuration/Share'
import * as BudgetAPI from '../lib/API/BudgetAPI'
import { SpendType } from '../lib/Enums/SpendType'
import { useBudget } from '../lib/Hooks/Budget'
import { useExpenses } from '../lib/Hooks/Spends'
import MenuButton from '../MenuButton'
import MenuLink from '../MenuLink'
import AddIncome from './AddIncome'
import AddSpend from './AddSpend'
import Allowance from './Allowance/Allowance'
import BalanceOverview from './BalanceOverview'
import BudgetDevelopment from './BudgetDevelopment'
import DownloadPaywall from './DownloadPaywall'
import ReconciliationInfo from './Reconciliation/ReconciliationInfo'

type Props = {
  viewMode: SpendType
}

const BudgetMenu: FC<Props> = ({ viewMode }) => {
  const [isActive, toggleActive] = useState(false)
  const [showAdd, toggleShowAdd] = useState<boolean>(false)
  const { showSidebar } = useContext(SidebarContext)
  const { setViewMode } = useContext(BudgetContext)
  const { data: budget } = useBudget()
  const { data: spends } = useExpenses(budget!)
  const navigate = useNavigate()
  const ref = useRef(null)

  const renderReconciliationAccounting = () => {
    if (budget!.reconciliation_accounting_id) {
      navigate(`/accounting/${budget!.reconciliation_accounting_id}/`)
    }
    return showSidebar(<ReconciliationInfo budget={budget!} />)
  }

  const openSidebar = (content: ReactNode) => {
    showSidebar(content)
    toggleActive(false)
  }

  useOnClickOutside(ref, () => toggleActive(false))

  return (
    <div className='flex flex-wrap justify-end mb-2 mt-2 sm:mt-0'>
      {viewMode === SpendType.EXPENSE && (
        <AddSpend toggleHide={() => toggleShowAdd(false)} show={showAdd} />
      )}
      {viewMode === SpendType.INCOME && (
        <AddIncome toggleHide={() => toggleShowAdd(false)} show={showAdd} />
      )}
      <div className='relative' ref={ref}>
        <Button
          theme='gray'
          className={`ml-2 cursor-pointer ${isActive ? 'rounded-b-none' : ''}`}
          onClick={() => toggleActive(!isActive)}
        >
          <MdMoreVert size='1.5rem' />
        </Button>
        {isActive && (
          <div className='absolute top-10 left-2 bg-white border-gray-300 border-2 px-4 pt-4 z-10'>
            <div className='sm:hidden'>
              <MenuLink
                title='Del budgettet'
                icon='MdShare'
                action={() => openSidebar(<Share />)}
              />
            </div>
            {viewMode === SpendType.EXPENSE && (
              <div className='md:hidden'>
                <MenuLink
                  title='Kategorier'
                  icon='MdCategory'
                  action={() => openSidebar(<CategoriesForm sheet={budget!} />)}
                />
              </div>
            )}
            <MenuLink
              title='Rådighedsbeløb'
              icon='MdAccountBalanceWallet'
              action={() =>
                openSidebar(
                  <Allowance items={spends!} setViewMode={setViewMode} />,
                )
              }
            />
            <MenuLink
              title='Udvikling'
              icon='MdAutoGraph'
              action={() => openSidebar(<BudgetDevelopment budget={budget!} />)}
            />
            <MenuLink
              title='Balanceoverblik'
              icon='MdInsertChart'
              action={() => openSidebar(<BalanceOverview spends={spends!} />)}
            />
            <MenuLink
              title='Afstemning'
              icon='MdLogout'
              action={renderReconciliationAccounting}
            />
          </div>
        )}
      </div>
      <div className='hidden sm:block'>
        <MenuButton
          sheet={budget!}
          title='Del budgettet'
          icon='MdShare'
          onClick={() => showSidebar(<Share />)}
        ></MenuButton>
      </div>
      {viewMode === SpendType.EXPENSE && (
        <div className='hidden md:block'>
          <MenuButton
            sheet={budget!}
            title='Kategorier'
            icon='MdCategory'
            onClick={() => showSidebar(<CategoriesForm sheet={budget!} />)}
          />
        </div>
      )}
      <MenuButton
        sheet={budget!}
        icon='MdDownload'
        onClick={() => BudgetAPI.downloadBudget(budget!)}
      >
        <DownloadPaywall />
      </MenuButton>
      <MenuButton
        sheet={budget!}
        icon='MdSettings'
        onClick={() => showSidebar(<Configuration sheet={budget!} />)}
      />
      <Button onClick={() => toggleShowAdd(true)} className='ml-2'>
        <div className='flex items-center'>
          <MdPostAdd size='1.25rem' className='mr-2' />
          Tilføj
        </div>
      </Button>
    </div>
  )
}

export default BudgetMenu

import Config from '../../../lib/Config'
import { SheetType } from '../../Sheets/lib/Enums/SheetType'

const BASE_URL = `${Config.API}/ai`

export const chat = async (
  content: string,
  sheetType: SheetType | null,
  sheetId: string | undefined,
) => {
  const payload = {
    message: content,
  } as { [key: string]: string }

  if (sheetType) {
    payload.sheet_type = sheetType
  }

  if (sheetId) {
    payload.sheet_id = sheetId
  }

  return await fetch(`${BASE_URL}/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify(payload),
    credentials: 'include',
  })
}
